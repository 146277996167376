import { IContext } from '../contexts/AuthProvider/types';
import { SlotProps } from '../pages/Gallery';

export interface FileProps {
    url: string;
    uuid: string;
    filename: string;
}

export async function uploadFileS3(file: any, auth: IContext, path: string) {
    const fileid = (URL.createObjectURL(file).split('/').pop() || '');
    const fileext = '.' + file.name.split('.').pop();
    const filename = file.name.split('.').slice(0, -1).join('.');
    const reader = new FileReader();

    return new Promise<any>((resolve, reject) => {
        reader.onload = async () => {
            try {
                const base64 = reader.result?.toString().split(',')[1];
                if (!base64) throw new Error('Falha ao converter arquivo para Base64');

                let response: any = await auth.post('/v1/uploadfile', {
                    path,
                    id: fileid + fileext,
                    base64: base64,
                });

                resolve({ url: response.data, uuid: fileid, filename });
            } catch (error) {
                console.error('Erro no upload:', error);
                reject(error);
            }
        };

        reader.onerror = () => {
            reject(new Error('Erro ao ler o arquivo'));
        };

        reader.readAsDataURL(file);
    });
}

export const selectAndUploadFile = (slop: SlotProps, auth: IContext, path: string) => {
    return new Promise<any>((resolve, reject) => {
        const input = document.createElement('input');
        input.type = 'file';

        if (slop.allowsImage && slop.allowsPdf) {
            input.accept = 'image/*,application/pdf';
        } else if (slop.allowsImage) {
            input.accept = 'image/*';
        } else if (slop.allowsPdf) {
            input.accept = 'application/pdf';
        }

        input.onchange = async (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
                const file = target.files[0];

                try {
                    const response = await uploadFileS3(file, auth, path);
                    resolve(response);
                } catch (error) {
                    console.error('Erro no upload:', error);
                    reject(error);
                }
            }
        };

        input.click();
    });
};
