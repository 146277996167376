import { Dropdown, Nav, NavDropdown } from "react-bootstrap"
import styled from "styled-components"
import { isMobileDevice } from "../../commons/utils"
import { useNavigate } from "react-router-dom"

export const ExNavDropdown = styled(NavDropdown)`
    font-size: 16px;
    margin-right: 10px;
    user-select: none;
`
export const ExNavIcon = styled.div`
    display: flex;
    margin-right: 5px;
    font-size: 18px;
`
export const ExDivider = styled.div`
    margin: 10px;
    margin-left: 14px;
    height: 1px;
    width: 200px;
    background-color: gray;
`
const NavDropdownItemStyled = styled(NavDropdown.Item)`
    font-size: 14px;
    padding: 2px 20px;
    border-radius: 5px;

    &:active {
        background-color: #fd6e27;
    }
`
export const DropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.65);
    border: none;
    font-size: 16px;
    min-width: ${isMobileDevice() ? `100%` : `200px`};
    padding-top: 26px;
    padding-bottom: 2px;

    &:hover {
        background-color: transparent !important;
        color: rgba(255, 255, 255, 0.9);
        border-color: rgba(255, 255, 255, 0.9) !important;;
    }
`;

export const DropdownItem = styled(Dropdown.Item)`
    background: transparent;
    border: none;
    height: 30px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding-left: 1rem;
    min-width: 200px;
    
    &:hover {
        background-color: var(--hover-background-color);
    }

    &:active {
        background-color: ${'var(--primary-color)'};
    }
`

export const ExNavDropdownItem = (props: any) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
        navigate(props.to);
    };

    return (
        <NavDropdownItemStyled onClick={handleClick}>
            {props.children}
        </NavDropdownItemStyled>
    );
};

const NavLinkStyled = styled(Nav.Link)`
    font-size: 16px;
    margin-right: 10px;
`

export const ExNavItem = (props: any) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
        navigate(props.to);
    };

    return (
        <NavLinkStyled onClick={handleClick}>
            {props.children}
        </NavLinkStyled>
    );
};

const NavDropdownContainer = styled.div`
    margin-bottom: 10px;
`

const NavDropdownHeaderStyled = styled(NavDropdown.Header)`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600; 
    padding-bottom: 4px;
`

export const ExNavDropdownHeader = (props: any) => {
    return (
        <NavDropdownContainer>
            <NavDropdownHeaderStyled>
                <ExNavIcon>{props.icon}</ExNavIcon>{props.title}
            </NavDropdownHeaderStyled>
            {props.children}
        </NavDropdownContainer>
    )
}

export const TitleToogle = styled.p`
    position: absolute;
    font-size: 11px;
    width: 100%;
    text-align: center;
    top: 8px;
`
