import { useRef, useState } from "react";
import { formatDataCompare, formatDateTime, formatDecimal, getLocalStorage, setLocalStorage, toastError } from "../../../commons/utils";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_movimento_single, model_tipo_movimento } from "../../../models";
import { Gallery, GalleryHandler, SlotProps } from "../../Gallery";
import { Paperclip } from "lucide-react";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const EstoquesMovimentos = () => {
    const auth = useAuth();

    const refdatatable = useRef<any>(null);
    const tipomovref = useRef<any>(null);
    const iemissaoref = useRef<any>(null);
    const femissaoref = useRef<any>(null);
    const refgallery = useRef<GalleryHandler>(null);

    const [showing, setShowing] = useState(false);

    const columns = [
        { field: 'movimentodescricao', header: 'Identificador', style: { whiteSpace: 'pre-wrap' } },
        { field: 'descricao', header: '', notSortable: true, style: { whiteSpace: 'pre-wrap' } },
        { field: 'movemissao', header: 'Emissão', body: (e: any) => formatDateTime(e.movemissao), dataType: 'date', style: { maxWidth: '100px' } },
        { field: 'volume', header: 'Volume (KG)', dataType: 'numeric', body: (e: any) => formatDecimal(e.volume), style: { maxWidth: '100px' } },
        { field: 'usunome', header: 'Usuário', style: { whiteSpace: 'pre-wrap' } },
        { field: 'tipmovstdescricao', header: 'Situação' },
        { field: 'functions', style: { width: '1rem' } }
    ];

    async function getFiles(tipmovcontador: number, movid: number) {
        try {
            const response_slots: any = await auth.get('/v1/tipomovimentoarquivo?tipmovcontador=' + tipmovcontador + '&tipmovarqativo=1');
            const response_files: any = await auth.get('/v1/movimentoarquivo?movid=' + movid + '&movarqativo=1');

            if (response_slots?.data) {
                const slots: SlotProps[] = response_slots.data.map((item: any) => {
                    const fileMatch = response_files.data.find((file: any) => file.movarqtipo === item.tipmovarqtipo);

                    return {
                        url: fileMatch ? fileMatch.movarqurl : undefined,
                        name: item.tipmovarqtipo,
                        allowsPdf: item.tipmovarqpdf,
                        allowsImage: item.tipmovarqimagem
                    };
                });

                return slots;
            } else {
                return [];
            }
        } catch (error: any) {
            toastError(`Erro ao carregar arquivos: ${error.message || error}`);
            return [];
        }
    }

    function getFunctions(row: any) {
        return [{
            click: async () => {
                const slots = await getFiles(row.tipmovcontador, row.movid);
                refgallery.current?.openModal(
                    slots,
                    row.movimentodescricao,
                    row.movid,
                    false
                );
            },
            icon: <Paperclip size={20} />,
            disabled: row.qtdearquivos === '0'
        }]
    }

    async function uploadMovimentoArquivo(movid: any, file: any) {
        const body = {
            movarqid: file.uuid,
            movid,
            movarqtipo: file.filename || 'Geral',
            movarqurl: file.url
        };

        try {
            await auth.post('/v1/movimentoarquivo', body);
        } catch (error: any) {
            toastError(error);
        }
    }

    function filterData(data: any, iEmissao: any, fEmissao: any, tipMov: any) {
        let _data: any = [];

        _data = data;

        if (iEmissao)
            _data = _data.filter((item: any) => formatDataCompare(item.movemissao) >= iEmissao);

        if (fEmissao)
            _data = _data.filter((item: any) => formatDataCompare(item.movemissao) <= fEmissao);

        if (tipMov && (tipMov.length > 0)) {
            const _tipMov = new Set(tipMov.map((item: any) => item.value));
            _data = _data.filter((item: any) => _tipMov.has(item.tipmovcontador));
        }

        setLocalStorage('i_emissao_tipmov_mov', iEmissao ?? null);
        setLocalStorage('f_emissao_tipmov_mov', fEmissao ?? null);

        refdatatable.current.setCustomData(_data);
    }

    function afterRefresDataTable(data: any) {
        var iEmissao;
        var fEmissao;

        if (!showing) {
            iEmissao = getLocalStorage('i_emissao_tipmov_mov');
            fEmissao = getLocalStorage('f_emissao_tipmov_mov');

            filterData(data, iEmissao, fEmissao, []);

            setShowing(true);
        } else
            filterData(data, iemissaoref.current.getValue(), femissaoref.current.getValue(), tipomovref.current.getValue())
    }

    async function changeEmissaoInicial(value: any, data: any) {
        filterData(data, value, femissaoref.current.getValue(), tipomovref.current.getValue())
    }

    async function changeEmissaoFinal(value: any, data: any) {
        filterData(data, iemissaoref.current.getValue(), value, tipomovref.current.getValue())
    }

    async function changeTipoMovimento(selecteds: any, data: any) {
        filterData(data, iemissaoref.current.getValue(), femissaoref.current.getValue(), selecteds)
    }

    const filters = [
        {
            ref: iemissaoref, md: 2, id: 'i_emissao_tipmov_mov', label: 'Emissão Inicial', type: 'date',
            onChange: changeEmissaoInicial, defaultValue: getLocalStorage('i_emissao_tipmov_mov')
        },
        {
            ref: femissaoref, md: 2, id: 'f_emissao_tipmov_mov', label: 'Emissão Final', type: 'date',
            onChange: changeEmissaoFinal, defaultValue: getLocalStorage('f_emissao_tipmov_mov')
        },
        {
            ref: tipomovref, md: 8, id: 'tipmov', label: 'Tipo Movimento', type: 'select',
            onChange: changeTipoMovimento, model: model_tipo_movimento, isMulti: true
        }
    ]

    return (
        <>
            <ConsultaPadrao
                ref={refdatatable}
                model={model_movimento_single}
                columns={columns}
                inputfilters={filters}
                onAfterRefresh={afterRefresDataTable}
                functions={getFunctions}
            />
            <Gallery ref={refgallery} onUploadFile={(movid, file) => uploadMovimentoArquivo(movid, file)} />
        </>
    )
}