import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';
import { lighten } from 'polished';
import { getCSSVariableValue } from '../../commons/utils';

const Icon = styled.div`
    display: flex;
    margin-right: 5px;
    pointer-events: none;
    background-color: transparent;
    align-items: center;
    justify-content: center;
`

interface ExButtonProps extends ButtonProps {
    icon?: any;
    color?: any;
    iconcenter?: any;
    secondary?: boolean;
}

export const ExButton = (props: ExButtonProps) => {
    const _color = getCSSVariableValue(props.color ? props.color : 'var(--primary-color)');

    return (
        <Button
            size={props.size ? props.size : 'sm'}
            {...props}
            style={{
                backgroundColor: props.secondary ? 'transparent' : _color,
                borderColor: _color,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                color: props.secondary ? _color : '#fff',
                ...props.style,
            }}
            onFocus={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.05rem ${_color}80, 0 0 0 0.1rem ${_color}40`;
            }}
            onBlur={(e: any) => {
                e.target.style.boxShadow = 'none';
            }}
            onMouseDown={(e: any) => {
                e.target.style.backgroundColor = props.secondary ? 'transparent' : lighten(0.1, _color);
                e.target.style.borderColor = lighten(0.1, _color);
            }}
            onMouseUp={(e: any) => {
                e.target.style.backgroundColor = props.secondary ? 'transparent' : _color;
                e.target.style.borderColor = _color;
            }}
        >
            {props.icon &&
                <Icon>
                    {props.icon}
                </Icon>
            }
            {props.iconcenter &&
                <Icon style={{ margin: 2 }}> {props.iconcenter} </Icon>
            }
            {props.children}
        </Button>
    )
}

interface RoundedButtonProps extends ExButtonProps { }

export const RoundedButton = (props: RoundedButtonProps) => {
    return <ExButton {...props} style={{ ...props.style, display: '', width: '30px', height: '30px', padding: 0, borderRadius: '50%' }} />;
};