import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_aplicativo_usuario } from "../../../models";
import { ExSwitch } from "../../../components/ExSwitch";
import { RoundedButton } from "../../../components/ExButton";
import { copyText } from "../../../commons/utils";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { Privilegio } from "../../../contexts/AuthProvider/types";
import { Copy } from "lucide-react";

const ButtonCopy = (props: any) => {
    return (
        props.url &&
        <div>
            <RoundedButton
                style={{ marginLeft: '10px' }}
                iconcenter={<Copy width={'18px'} />}
                onClick={() => copyText(props.url)}
            />
        </div>
    )
}

export const AplicativosUsuario = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const [show, setShow] = useState(false);
    const [usucodigo, setUsucodigo] = useState();

    function openModal(_data: any) {
        setUsucodigo(_data.usucodigo);
        setShow(true);
    }

    function closeModal() {
        if (props.onRefresh)
            props.onRefresh();

        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    async function updateStatus(_body: any) {
        _body.usucodigo = usucodigo;

        try {
            await auth.post('/v1/aplicativotouser', _body);
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        { field: 'aplinome', header: 'Nome' },
        { field: 'apliurlandroid', header: 'URL Android', body: (e: any) => <ButtonCopy url={e.apliurlandroid} /> },
        { field: 'apliurlios', header: 'URL IOS', body: (e: any) => <ButtonCopy url={e.apliurlios} /> },
        {
            field: 'apliusuativo', header: 'Habilitado', body: (row: any) =>
                (
                    (row.aplicodigo === 1 && auth.useAppRequisicoes) ||
                    (row.aplicodigo === 2 && auth.useAppEstoques) ||
                    (row.aplicodigo === 4 && auth.useAppDP) ||
                    auth.privilegio === Privilegio.Admnistrador
                ) ?
                    <ExSwitch
                        defaultChecked={row.apliusuativo === 1}
                        onChange={(e: any) => updateStatus({ aplicodigo: row.aplicodigo, apliusuativo: e.target.checked ? 1 : 0 })}
                    /> :
                    <> </>
        }
    ];

    return (
        <Modal
            show={show}
            onHide={closeModal}
            size="xl"
            centered={true}
        >
            <ConsultaPadrao
                model={model_aplicativo_usuario}
                columns={columns}
                sortField="aplicodigo"
                apifilter={"?usucodigo=" + usucodigo}
            />
        </Modal>
    )
});