import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { Modal, ModalRef } from "../../../../components/Modal";
import { toastError } from "../../../../commons/utils";
import { model_produto } from "../../../../models";
import { ExSelect } from "../../../../components/ExSelect";
import { PackageOpen } from "lucide-react";

export interface ProdutoUnidMedProdRef {
    openModal: (id: string) => void;
    closeModal: () => void;
}

export const ProdutoUnidadeMedidaProduto = forwardRef<ProdutoUnidMedProdRef, any>((props: any, ref) => {
    const auth = useAuth();

    const [produtos, setProdutos] = useState<any[]>([]);
    const [prodUnidId, setProdUnidId] = useState<any>();

    const refmodal = useRef<ModalRef>(null);
    const refinputprod = useRef<any>();

    async function getProdutos(id: string) {
        setProdUnidId(id);

        await auth.get('/v1/produtounidademedidaprodutocrm?produnidid=' + id)
            .then((response: any) => {
                var _produtos = response.data.map((item: any) => ({
                    value: item.prodid,
                    label: item.proddescricao
                }));

                setProdutos(_produtos);
                refinputprod.current.setValue(_produtos);
            })
            .catch((error: any) => toastError(error));
    }

    function openModal(id: string) {
        getProdutos(id);
        refmodal.current?.openModal();
    }

    function closeModal() {
        refmodal.current?.closeModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    async function handleSuccess() {
        const refValues = refinputprod.current.getValue();

        var body = [...produtos.map((item: any) => ({
            prodid: item.value,
            produnidid: prodUnidId,
            produnidprodativo: 0
        }))];

        refValues.forEach((item: any) => {
            const existingIndex = body.findIndex(p => p.prodid === item.value);

            if (existingIndex !== -1) {
                body[existingIndex].produnidprodativo = 1;
            } else {
                body.push({
                    prodid: item.value,
                    produnidid: prodUnidId,
                    produnidprodativo: 1
                });
            }
        });

        await auth.post('/v1/produtounidademedidaproduto', body)
            .then(() => refmodal.current?.closeModal())
            .catch((error: any) => toastError(error));
    }


    return (
        <Modal
            ref={refmodal}
            title={'Relação Unidade Medida x Produto'}
            icon={<PackageOpen style={{ fontSize: '24px' }} />}
            size={'lg'}
            success={() => handleSuccess()}
        >
            <ExSelect ref={refinputprod} id='produnidmedprod' label='Produtos' model={model_produto} isMulti />
        </Modal >
    )
});