import { Card, Form, Row } from "react-bootstrap"
import { Input } from "../../components/Input"
import { useRef, useState } from "react";
import { ExButton } from "../../components/ExButton";
import styled from "styled-components";
import LogoPequena from "../../images/logoPequena.png"
import LogoPequenaLigth from "../../images/logoPequenaLigth.png"
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { isMobileDevice } from "../../commons/utils";
import { Api } from "../../api/adress";
import { LogIn } from "lucide-react";
import { useThemeContext } from "../../theme";

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-width: 100%;
`
const LoginWrapper = styled.div`
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: ${isMobileDevice() ? '100%' : '350px'};
    max-height: ${isMobileDevice() ? '100%' : '600px'};
    background-color: var(--background-color);
    box-shadow: rgba(93, 93, 93, 0.3) 0px 0px 15px 2px;
`
const Logo = styled.img`
    width: 100%;
    padding: 0 3.5rem;
`

export const Login = () => {
    const auth = useAuth();
    const { themeMode } = useThemeContext();

    const usernameRef = useRef<any>();
    const passwordRef = useRef<any>();

    const [validated, setValidated] = useState(false);

    async function getGrupos(token: string) {
        const config = { headers: { 'Authorization': `Bearer ${token}` } }

        await Api.get('/v1/grupocrm?grutipo=0', config)
            .then((response: any) => {
                auth.setGruSuperiores(response.data);
                authenticate(response.data[0]);
            })
            .catch((error: any) => {
                console.log(error)
                var msg = error.response.data.message ? error.response.data.message : 'Não foi possível encontrar os grupos do usuário.';
                toast.error(msg, { toastId: msg });
            })
    }

    async function authenticate(grupo?: any) {
        var usuemail = usernameRef.current.getValue();
        var ususenha = passwordRef.current.getValue();

        await auth.authenticate(usuemail, ususenha, grupo)
            .then((token: any) => {
                usernameRef.current.setError(false);
                passwordRef.current.setError(false);

                if (!grupo)
                    getGrupos(token)
                else
                    toast.success('Login realizado com sucesso.');
            })
            .catch((error: any) => {
                setValidated(false);
                usernameRef.current.setError(true);
                passwordRef.current.setError(true);

                var msg = error.response.data.message ? error.response.data.message : 'Não foi possível realizar a autenticação no momento.';

                toast.error(msg, { toastId: msg });
            });
    }

    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            authenticate()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <LoginContainer>
            <LoginWrapper>
                <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                    <Row className="mb-5 pt-4 justify-content-center">
                        {themeMode === 'dark' ?
                            <Logo src={LogoPequena} /> :
                            <Logo src={LogoPequenaLigth} />}
                    </Row>
                    <Row className="justify-content-center text-center" >
                        <Card className="border-0 p-0" style={{ backgroundColor: 'var(--background-color)' }}>
                            <Card.Body>
                                <Card.Title>BEM-VINDO</Card.Title>
                                <Card.Text className="mb-4">Preencha os dados de login para acessar</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row className="mb-2 justify-content-center">
                        <Input ref={usernameRef} id="email" type="text" label="E-mail" required model='email' />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <Input ref={passwordRef} id="senha" type="password" label="Senha" required />
                    </Row>
                    <Row className="mb-2 justify-content-end m-0">
                        <ExButton type="submit" icon={<LogIn />} style={{ width: '100px' }} >Acessar</ExButton>
                    </Row>
                </Form>
            </LoginWrapper>
        </LoginContainer >
    )
}