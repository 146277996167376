import { useEffect, useRef, useState } from "react"
import KanbanBoard from "../../../components/Kanban/Kanban";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { ButtonCard, formatDateTime, formatMonetary, toastError } from "../../../commons/utils";
import { FiltroRequisicoes } from "./Filtro";
import { useReactToPrint } from "react-to-print";
import parse from 'html-react-parser'
import moment from "moment";
import { Paperclip, Printer, Redo, Undo } from "lucide-react";
import { Gallery, GalleryHandler } from "../../Gallery";
import { getCampos, getFilesRequisicao, getStatus, getUsuPrivilegio, hasPrivilegio, model_observacao, updateStatus, uploadFileRequisicao } from "./../utils";
import { ExCadastroPadrao } from "../../../components/ExCadastroPadrao";

function createButton(hint: string, color: string, icon: JSX.Element, onClick: () => void) {
    return (
        <ButtonCard hint={hint} color={color} iconcenter={icon} onClick={onClick} className='p-1 me-1' />
    );
}

export const RequisicoesKanban = () => {
    const auth = useAuth();

    const refKanbam = useRef<any>();
    const refFiltro = useRef<any>();
    const contentRef = useRef<HTMLDivElement>(null)
    const reactToPrintFn = useReactToPrint({ contentRef, ignoreGlobalStyles: true });
    const refgallery = useRef<GalleryHandler>(null);
    const refmodal = useRef<any>(null);

    const [status, setStatus] = useState<any>();
    const [usuPrivilegios, setUsuPrivilegios] = useState<any>();
    const [campos, setCampos] = useState<any>();
    const [htmlArq, setHtmlArq] = useState()


    useEffect(() => {
        async function loadData() {
            const [privilegios, campos, status] = await Promise.all([
                getUsuPrivilegio(auth),
                getCampos(auth),
                getStatus(auth)
            ]);

            setUsuPrivilegios(privilegios);
            setCampos(campos);
            setStatus(status);
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (htmlArq) {
            reactToPrintFn()
            setHtmlArq(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [htmlArq])

    const generatePDF = async (reqid: number, usucodigo: number) => {
        const getHistoricoStatus = () => ''

        auth.get('/v1/requisicaorelatorio').then((resp: any) => {
            auth.get('/v1/requisicaopdf', { params: { reqid, usucodigo } })
                .then((response: any) => {
                    // eslint-disable-next-line no-new-func
                    const html = new Function('moment', 'formatFloat', 'getHistoricoStatus', `return \`${resp.data[0].reqrelcorpo}\``).call({
                        ...response.data,
                        date: moment().format('DD/MM/YYYY HH:mm:ss'),
                    }, moment, formatMonetary, getHistoricoStatus)

                    setHtmlArq(html)
                })
                .catch((error: any) => {
                    toastError(error);
                })
        })
    }

    function onGetData(data: any) {
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        const sequencias = status.map((item: any) => item.sequencia);

        const menorSequencia = Math.min(...sequencias);
        const maiorSequencia = Math.max(...sequencias);

        data.forEach((item: any) => {
            const { reqid, fornome, statid, imobdescricao, reqobservacao, impdescricao, reqnumero, impprioridade, reqdata,
                total_requisitado, reqdesconto, usucodigo, statgerapdf, statsequencia } = item;

            const id = `${reqid} - ${usucodigo}`;
            const content =
                <div style={{ paddingTop: '5px' }}>
                    {imobdescricao && <div style={{ paddingBottom: '5px' }}>{imobdescricao}</div>}
                    {fornome && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{fornome}</div>}
                    {reqobservacao && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{reqobservacao}</div>}
                    {reqdata && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{formatDateTime(reqdata)}</div>}
                    {((total_requisitado > 0) || (reqdesconto > 0)) &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ fontSize: '14px' }}>
                                {reqdesconto ?
                                    <div style={{ fontSize: '12px' }}>{formatMonetary(total_requisitado) + ' - ' + formatMonetary(reqdesconto) + ' = '}
                                        <span style={{ fontSize: '14px' }}>{formatMonetary(total_requisitado - reqdesconto).toString()}</span>
                                    </div> :
                                    formatMonetary(total_requisitado)}
                            </div>
                        </div>
                    }
                </div>

            const columnId = statid;

            const details =
                <div>
                    <div>{impdescricao}</div>
                    <div style={{ fontSize: '14px', fontWeight: '600', color: impprioridade === 1 ? '#f15619' : 'none' }}>{reqnumero + (impprioridade === 1 ? ' - Urgente' : '')}</div>
                </div>

            const color = status.find((_status: any) => _status.value === statid)?.color;

            const functions = (
                <>
                    {(statgerapdf === 1) && createButton('Imprimir', color, <Printer size={20} />, () => generatePDF(reqid, usucodigo))}
                    {
                        <>
                            {(statsequencia !== menorSequencia) && (hasPrivilegio(status, usuPrivilegios, campos, statid, false)) &&
                                createButton('Retroceder Status', color, <Undo size={20} />,
                                    () => refmodal.current.openModal({ statid: item.statid, reqid: item.reqid, usucodigo: item.usucodigo })
                                )}

                            {(statsequencia !== maiorSequencia) && (hasPrivilegio(status, usuPrivilegios, campos, statid, true)) &&
                                createButton('Avançar Status', color, <Redo size={20} />,
                                    () => updateStatus(auth, status, usuPrivilegios, campos, item, true).then(() => refFiltro.current.refresh())
                                )}
                        </>
                    }
                    {createButton('Arquivos', color, <Paperclip size={20} />,
                        async () => {
                            const files = await getFilesRequisicao(auth, reqid);
                            refgallery.current?.openModal(files, '', reqid, true, usucodigo);
                        }
                    )}
                </>
            );

            const filterValues: string[] = [imobdescricao, fornome, reqobservacao, reqnumero]

            newArray.push({ id, content, columnId, details, undefined, functions, filterValues });
        });

        refKanbam.current.setTasks(newArray);
    }

    return (
        status &&
        <>
            <div style={{ display: 'none' }} >
                {htmlArq &&
                    <div ref={contentRef}>
                        {parse(htmlArq)}
                    </div>}
            </div>
            <KanbanBoard
                ref={refKanbam}
                columnsId={status?.map((item: any) => item.id)}
                status={status}
                filter={() => {
                    return <FiltroRequisicoes ref={refFiltro} onGetData={onGetData} />
                }}
            />
            <Gallery ref={refgallery} onUploadFile={(reqid, file, usucodigo) => uploadFileRequisicao(auth, reqid, file, usucodigo)} />
            <ExCadastroPadrao ref={refmodal} model={model_observacao}
                success={(values: any) => {
                    updateStatus(auth, status, usuPrivilegios, campos, values, false)
                        .then(() => refFiltro.current.refresh())
                }} />
        </>
    )
}