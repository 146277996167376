import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { formatDataCompare, getLocalStorage, isMobileDevice, setLocalStorage, toastError } from "../../../../commons/utils";
import { Grid } from "@mui/material";
import { Input } from "../../../../components/Input";
import { ExSelect } from "../../../../components/ExSelect";
import { model_colaborador, model_grupo_consulta } from "../../../../models";

export const Filtro = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const refadmissaoi = useRef<any>();
    const refadmissaof = useRef<any>();
    const refgrupo = useRef<any>();

    const [data, setData] = useState<any>();

    function refresh() {
        change(true);
    }

    useImperativeHandle(ref, () => ({
        refresh
    }))

    async function getDados(refresh: boolean, iAdmissao?: any, fAdmissao?: any, grupos?: any) {
        let _data: any;

        if (!refresh && data)
            _data = data;
        else {
            await auth.get(model_colaborador.api_get)
                .then((response: any) => {
                    setData(response.data);
                    _data = response.data;
                })
                .catch((error: any) => {
                    toastError(error);
                })
        }

        let _iAdmissao = iAdmissao;
        let _fAdmissao = fAdmissao;
        let _grupos = grupos;

        if (!_iAdmissao && (_iAdmissao !== ''))
            _iAdmissao = refadmissaoi.current.getValue();

        if (!_fAdmissao && (_fAdmissao !== ''))
            _fAdmissao = refadmissaof.current.getValue();

        if (!_grupos)
            _grupos = refgrupo.current.getValue();

        if (_iAdmissao && (_iAdmissao !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.coladmissao) >= _iAdmissao);

        if (_fAdmissao && (_fAdmissao !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.coladmissao) <= _fAdmissao);

        if (_grupos && (_grupos.length > 0)) {
            const gruposValues = _grupos.map((grupo: any) => grupo.value);
            _data = _data.filter((item: any) => gruposValues.includes(item.gruid));
        }

        // Armazena os filtros 
        setLocalStorage('i_admissao_kanban_colaboradores', _iAdmissao ?? null);
        setLocalStorage('f_admissao_kanban_colaboradores', _fAdmissao ?? null);

        return _data;
    }

    function change(refresh: boolean, iAdmissao?: any, fAdmissao?: any, grupos?: any) {
        getDados(refresh, iAdmissao, fAdmissao, grupos).then((data: any) => {
            if (props.onGetData)
                props.onGetData(data);
        })
    }

    function changeAdmissaoInicial(_newValue: any) { change(false, _newValue) }
    function changeAdmissaoFinal(_newValue: any) { change(false, null, _newValue) }
    function changeGrupo(_newValue: any) { change(false, null, null, _newValue) }

    useEffect(() => {
        change(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={12} >
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={isMobileDevice() ? 6 : 2}>
                    <Input ref={refadmissaoi} id="i_admissao_kanban_colaboradores" type="date" label="Admissão Inicial" onAfterChange={changeAdmissaoInicial} defaultValue={getLocalStorage('i_admissao_kanban_colaboradores') ? getLocalStorage('i_admissao_kanban_colaboradores') : ''} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 6 : 2}>
                    <Input ref={refadmissaof} id="f_admissao_kanban_colaboradores" type="date" label="Admissão Final" onAfterChange={changeAdmissaoFinal} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 3}>
                    <ExSelect ref={refgrupo} id='grupo' label='Grupo' model={model_grupo_consulta} onAfterChange={changeGrupo} isMulti />
                </Grid>
            </Grid>
        </Grid>
    )
})