import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { convertImageToBase64, formatDecimal, formatMonetary } from '../../commons/utils';
import Logo_Branca from '../../images/logoBranca.png'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

async function Header(tipoMovimento?: string) {
    let imgLogo;

    try {
        imgLogo = await convertImageToBase64(Logo_Branca);
    } catch (error) {
        console.log(error);
    }

    return {
        stack: [
            { canvas: [{ type: 'rect', x: 20, y: 10, w: 555, h: 35, r: 5, color: 'var(--primary-color)' }] },
            {
                table: {
                    widths: ['*'],
                    body: [
                        [{ image: imgLogo, alignment: 'left', width: 80, margin: [30, 20, 0, 0] }],
                        [{ text: 'MOVIMENTOS POR PERIODO', bold: true, alignment: 'center', fontSize: 12, margin: [0, -28, 0, 0], color: '#fff' }],
                        [{ text: tipoMovimento?.toUpperCase() ?? '', bold: true, alignment: 'center', fontSize: 9, margin: [0, -16, 0, 0], color: '#fff' }],
                    ],
                },
                layout: 'noBorders',
                margin: [0, -50, 0, 0]
            }
        ]
    }
}

async function Body(columns: any) {
    return columns.filter((column: any) => column.field !== 'edit').map((column: any) => ({
        text: column.header,
        style: 'tableHeader',
        fontSize: 9,
        bold: true,
        alignment: column.dataType === 'numeric' ? 'right' : 'left',
    }));
}

async function Dados(data: any, columns: any) {
    const _ = require('lodash');

    return data.map((register: any) =>
        columns.map((column: any) => {
            if (column.field !== 'edit') {
                let _body = column.body ? column.body(register) : _.get(register, column.field);
                if (_body?.props) _body = _body.props.children;
                return {
                    text: _body,
                    fontSize: 8,
                    alignment: column.dataType === 'numeric' ? 'right' : 'left',
                    margin: [0, 2.5, 0, 2.5],
                };
            }
            return null;
        }).filter(Boolean)
    );
}

async function Widths(columns: any) {
    return columns.map((column: any) => {
        if (['proddescricao', 'pldescricao', 'cultcatdescricao'].some(field => column.field.includes(field))) {
            return '*';
        }
        return 'auto';
    });
}

async function Content(data: any, columns: any) {
    const hasValorTotalColumn = columns.some((column: any) => column.field === 'valortotal');

    const body = await Body(columns);
    const dados = await Dados(data, columns);
    const widths = await Widths(columns);
    const footer = await Footer(data, hasValorTotalColumn);

    return [
        {
            table: {
                headerRows: 1,
                widths: widths,
                body: [body, ...dados],
            },
            layout: {
                fillColor: (i: number) => (i === 0 ? null : i % 2 === 0 ? '#fcf3ec' : null),
                hLineColor: () => '#c9c9c9',
                vLineColor: () => 'white',
                hLineWidth: () => 1,
            },
        },
        footer,
    ];
}

async function Footer(data: any, hasValorTotalColumn: boolean,) {
    const total_geral = data?.reduce((total: number, item: any) => total + item.valortotal, 0);
    const total_embalagens = data?.reduce((total: number, item: any) => total + item.qtdebaseunid, 0);
    const total_pesagem = data?.reduce((total: number, item: any) => total + item.qtdebase, 0);

    const areaSet = new Set();

    const _totalArea = data?.reduce((total: number, item: any) => {
        if (!areaSet.has(item.plid)) {
            areaSet.add(item.plid);
            return total + item.plarea;
        }
        return total;
    }, 0);

    const unitariomedio = total_geral && total_pesagem ? total_geral / total_pesagem : 0;

    const body_footer: any[] = [];

    body_footer.push(
        [{ text: 'Total Embalagens:', bold: true, alignment: 'right' }, { text: formatDecimal(total_embalagens), bold: true, alignment: 'right' }],
        [{ text: 'Total Pesagem:', bold: true, alignment: 'right' }, { text: formatDecimal(total_pesagem), bold: true, alignment: 'right' }],
        [{ text: 'Total Ha:', bold: true, alignment: 'right' }, { text: formatDecimal(_totalArea), bold: true, alignment: 'right' }],
        [{ text: 'Pesagem por Ha:', bold: true, alignment: 'right' }, { text: formatDecimal(total_pesagem / _totalArea), bold: true, alignment: 'right' }]
    );

    if (hasValorTotalColumn) {
        body_footer.push(
            [{ text: 'Unitário Médio:', bold: true, alignment: 'right' }, { text: formatMonetary(unitariomedio), bold: true, alignment: 'right' }],
            [{ text: 'Total Geral:', bold: true, alignment: 'right' }, { text: formatMonetary(total_geral), bold: true, alignment: 'right' }]
        );
    }

    return {
        columns: [
            { width: 'auto', text: `*${data.length}`, alignment: 'left', fontSize: 9, bold: true },
            {
                table: {
                    widths: ['*', 'auto'],
                    body: body_footer,
                },
                layout: {
                    vLineColor: () => 'white',
                    hLineWidth: () => 0,
                },
                style: { fontSize: 9 },
            },
        ],
    };
}

export async function PDFReportVendasPorPeriodo(data: any, columns: any, tipoMovimento: string) {
    const header = await Header(tipoMovimento);
    const content = await Content(data, columns);

    const docDefinitions: any = {
        pageSize: 'A4',
        pageMargins: [20, 60, 20, 45],
        header,
        content
    };

    return new Promise((resolve) => {
        pdfMake.createPdf(docDefinitions).getBlob((blob) => {
            resolve(blob);
        });
    });
}
