import { useRef } from "react";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_produto_unidademedida } from "../../../models";
import { ProdutoUnidadeMedidaProduto, ProdutoUnidMedProdRef } from "./ProdutoUnidadeMedidaProduto";
import { PackageOpen } from "lucide-react";

export const ProdutoUnidadeMedida = () => {
    const refProduto = useRef<ProdutoUnidMedProdRef>(null);

    const columns = [
        { field: 'produnidid', header: 'Código' },
        { field: 'produniddescricao', header: 'Descrição' },
        { field: 'produnidativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    function additionalFunctions(row: any) {
        let _result = [];

        _result.push({ click: () => refProduto.current?.openModal(row.produnidid), icon: <PackageOpen size={20} />, color: 'var(--primary-color)' })

        return _result;
    }

    return (
        <>
            <ConsultaPadrao model={model_produto_unidademedida} columns={columns} sortField="produniddescricao" functions={additionalFunctions} />
            <ProdutoUnidadeMedidaProduto ref={refProduto} />
        </>
    )
}