import { isMobileDevice } from "../../../commons/utils";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_tipo_movimento_status } from "../../../models";

export const TipoMovimentoStatus = () => {
    function drawColors(e: any) {
        return (
            <div style={{
                backgroundColor: e.tipmovstcor,
                maxWidth: isMobileDevice() ? '100%' : '10px',
                width: '100%',
                height: isMobileDevice() ? '3px' : '30px',
                borderRadius: '2px'
            }} />
        )
    }

    const columns = [
        { field: 'tipmovstcor', header: '', notSortable: true, body: (e: any) => drawColors(e), style: { width: '2rem' } },
        { field: 'tipmovstsequencia', header: 'Sequência' },
        { field: 'tipmovstdescricao', header: 'Descrição' },
        { field: 'tipmovdescricao', header: 'Tipo Movimento' },
        { field: 'tipmovstacao', header: 'Ação' },
        { field: 'tipmovstativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_tipo_movimento_status} columns={columns} sortField="tipmovstsequencia" />
    )
}