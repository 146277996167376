import { useNavigate } from "react-router-dom";
import { Login } from "../../pages/Login";
import { useAuth } from "./useAuth";
import { Privilegio } from "./types";
import { AcessoRestrito } from "../../pages/AcessoRestrito";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/')
        return <Login />
    }

    return children;
}

export const RequireAuthGestao = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (auth.privilegio === Privilegio.Normal) {
        return <AcessoRestrito />
    }

    return children;
}

export const RequireAuthAdm = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (auth.privilegio !== Privilegio.Admnistrador) {
        return <AcessoRestrito />
    }

    return children;
}

export const RequireAuthRequisicoes = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!auth.useAppRequisicoes) {
        return <AcessoRestrito />
    }

    return children;
}

export const RequireAuthEstoques = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!auth.useAppEstoques) {
        return <AcessoRestrito />
    }

    return children;
}

export const RequireAuthDP = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    console.log(auth)
    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!auth.useAppDP) {
        return <AcessoRestrito />
    }

    return children;
}