import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_produto_unidade_conversao } from "../../../models";

export const ProdutoUnidadeConversao = () => {
    const columns = [
        { field: 'uniddescricao', header: 'Unidade Medida' },
        { field: 'uniddescricaobase', header: 'Unidade Medida Base' },
        { field: 'produnidconvfator', header: 'Fator Conversão' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_produto_unidade_conversao} columns={columns} sortField="uniddescricao" />
    )
}