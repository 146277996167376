import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { AppRoutes } from '../../routes';
import { isMobileDevice } from '../../commons/utils';
import { useEffect, useState } from 'react';
import { ExAppBar, ExDrawer, ExIconButtonAuxSideBar, ExMain } from './SideBar/components';
import { Box, Toolbar } from '@mui/material';
import { SideBar } from './SideBar';
import { SwitchTheme } from '../../components/SwitchTheme';
import { useThemeContext } from '../../theme';
import { Dropdown } from 'react-bootstrap';
import { DropdownItem, DropdownToggle, TitleToogle } from '../../components/ExNavbar';
import { Icon } from 'lucide-react';
import { farm } from '@lucide/lab';

export const Principal = () => {
    const auth = useAuth();
    const { themeMode, toggleTheme } = useThemeContext();

    const [expanded, setExpanded] = useState<any>(true);

    async function alterUser(grupo: string, refresh: boolean) {
        if (auth.usuemail && auth.ususenha) {
            await auth.authenticate(auth.usuemail, auth.ususenha, grupo)
                .then(() => {
                    if (refresh)
                        window.location.reload()
                })
        }
    }

    const clickExpanded = () => {
        setExpanded(!expanded)
    }

    const clickItemMenu = (to: any) => {
        setExpanded(to === undefined ? true : !isMobileDevice());
    };

    useEffect(() => {
        if (auth.grupo)
            alterUser(auth.grupo, false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box style={{ display: 'flex' }}>
            <ExAppBar position="fixed" open={expanded}>
                <Toolbar id='principal-toolbar' variant='dense' style={{ backgroundColor: 'var(--side-bar-color)' }} >
                    <ExIconButtonAuxSideBar onClick={clickExpanded} />
                    <SwitchTheme
                        defaultChecked={themeMode === 'dark'}
                        sx={{ position: `${isMobileDevice() ? 'fixed' : 'relative'}`, left: `${isMobileDevice() ? '3rem' : '0'}` }}
                        onChange={toggleTheme}
                    />

                    {(auth.grupos) &&
                        <Dropdown style={{ position: 'fixed', right: 0, marginRight: '20px' }}>
                            <DropdownToggle>
                                <TitleToogle>
                                    <Icon iconNode={farm} height={'20px'} style={{ marginRight: '5px', marginBottom: '3px' }} />
                                    Selecione o grupo:
                                </TitleToogle>
                                {auth.grupo.grudescricao}
                            </DropdownToggle>

                            <Dropdown.Menu>
                                <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                                    {
                                        auth.grupos.sort((a: any, b: any) => a.grudescricao.localeCompare(b.grudescricao)).map((grupo: any, index: any) => (
                                            <DropdownItem key={index} onClick={() => alterUser(grupo, true)}>{grupo.grudescricao}</DropdownItem>
                                        ))
                                    }
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </Toolbar>
            </ExAppBar>

            <ExDrawer open={expanded} variant={isMobileDevice() ? "temporary" : "persistent"} >
                <SideBar clickExpanded={clickExpanded} clickItemMenu={clickItemMenu} />
            </ExDrawer>

            <ExMain open={expanded}>
                <div style={{ padding: '2px', marginTop: '48px', backgroundColor: 'var(--background-color)', borderTopLeftRadius: '6px' }}>
                    <AppRoutes />
                </div>
            </ExMain>
        </Box >
    )
}