import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { ExSwitch } from "../../../components/ExSwitch";
import { model_status } from "../../../models";

export const Status = () => {
    const columns = [
        { field: 'statsequencia', header: 'Sequência' },
        { field: 'statid', header: 'Código' },
        { field: 'statdescricao', header: 'Nome' },
        { field: 'statacao', header: 'Ação' },
        { field: 'statgerapdf', header: 'Gera PDF', body: (e: any) => <ExSwitch checked={e.statgerapdf === 1} /> },
        { field: 'statassinatura', header: 'Assinatura', body: (e: any) => <ExSwitch checked={e.statassinatura === 1} /> },
        { field: 'statativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_status} columns={columns} sortField="statsequencia" />
    )
}