import { useEffect, useState } from "react";
import { Notification } from "./components/Notification";
import { useAuth } from "./contexts/AuthProvider/useAuth";
import { Login } from "./pages/Login";
import { Principal } from "./pages/Principal";
import { ThemeProviderWrapper } from "./theme";

function App() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.usunome !== null)
      setLoading(false);
  }, [auth.usunome]);

  if (loading)
    return <div>Loading...</div>;

  return (
    <ThemeProviderWrapper>
      {!auth.token ? <Login /> : <Principal />}

      < Notification />
    </ThemeProviderWrapper>
  );
}

export default App;
