import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_tipo_movimento_produto } from "../../../models";

export const TipoMovimentoProduto = () => {
    const columns = [
        { field: 'tipmovdescricao', header: 'Tipo de Movimento' },
        { field: 'produtoentrada', header: 'Produto Entrada' },
        { field: 'produtosaida', header: 'Produto Saída' },
        { field: 'tipmovprodativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_tipo_movimento_produto} columns={columns} sortField="tipmovdescricao" />
    )
}