import React from 'react';
import styled from 'styled-components';
import Logo from '../../images/logoPequena.png';
import { LockKeyhole } from 'lucide-react';

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
`;

const Image = styled.img`
    width: 300px;
`;

const TextContainer = styled.div`
    display: flex;
    margin-top: 1.2rem;
`;

const Text = styled.h4`
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 0.5rem;
`;

const LockIcon = styled(LockKeyhole)`
    width: 40px;
    height: 40px;
`;

export const AcessoRestrito = (props: any) => {
    return (
        <FlexContainer>
            <Image src={Logo} alt="" />
            <TextContainer>
                <LockIcon />
                <Text>{props?.description ? props?.description : 'Acesso Restrito'}</Text>
            </TextContainer>
        </FlexContainer>
    );
};
