import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { Modal, ModalRef } from "../../../../components/Modal";
import { toastError } from "../../../../commons/utils";
import { model_valores_complementares } from "../../../../models";
import { ExSelect } from "../../../../components/ExSelect";
import { GitPullRequestArrow } from "lucide-react";

export interface TipoMovimentoValoresRef {
    openModal: (id: string) => void;
    closeModal: () => void;
}

export const TipoMovimentoValores = forwardRef<TipoMovimentoValoresRef, any>((props: any, ref) => {
    const auth = useAuth();

    const [valores, setValores] = useState<any[]>([]);
    const [tipMovContador, setTipMovContador] = useState<any>();

    const refmodal = useRef<ModalRef>(null);
    const refinputval = useRef<any>();

    async function getValores(id: string) {
        setTipMovContador(id);

        await auth.get('/v1/tipomovimentovalorescrm?tipmovcontador=' + id)
            .then((response: any) => {
                var _valores = response.data.map((item: any) => ({
                    value: item.valid,
                    label: item.valnome
                }));

                setValores(_valores);
                refinputval.current.setValue(_valores);
            })
            .catch((error: any) => toastError(error));
    }

    function openModal(id: string) {
        getValores(id);
        refmodal.current?.openModal();
    }

    function closeModal() {
        refmodal.current?.closeModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    async function handleSuccess() {
        const refValues = refinputval.current.getValue();

        var body = [...valores.map((item: any) => ({
            valid: item.value,
            tipmovcontador: tipMovContador,
            tipmovvalativo: 0
        }))];

        refValues.forEach((item: any) => {
            const existingIndex = body.findIndex(v => v.valid === item.value);

            if (existingIndex !== -1) {
                body[existingIndex].tipmovvalativo = 1;
            } else {
                body.push({
                    valid: item.value,
                    tipmovcontador: tipMovContador,
                    tipmovvalativo: 1
                });
            }
        });

        await auth.post('/v1/tipomovimentovalores', body)
            .then(() => refmodal.current?.closeModal())
            .catch((error: any) => toastError(error));
    }


    return (
        <Modal
            ref={refmodal}
            title={'Relação Tipo Movimento x Valores Complementares'}
            icon={<GitPullRequestArrow style={{ fontSize: '24px' }} />}
            size={'lg'}
            success={() => handleSuccess()}
        >
            <ExSelect ref={refinputval} id='valorescomp' label='Valores Complementares' model={model_valores_complementares} isMulti />
        </Modal >
    )
});