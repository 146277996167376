import styled from "styled-components";
import { ExButton } from "../../components/ExButton";

// Adicionando o Overlay
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const FileNameContainer = styled.div`
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: var(--primary-color);
  height: 20px;
  border-radius: 4px;
  margin-top: 5px;
  white-space: nowrap;  
  overflow: hidden;     
  text-overflow: ellipsis; 
`;

export const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
`;

export const FileContainer = styled.div`
  display: flex;
  position: relative;
  border-radius: 8px;
  border: 2px dotted #ccc;
  padding: 10px;
  height: 225px; 
  flex-direction: column;
  overflow: hidden; 
`;

export const Thumbnail = styled.img`
  display: block;
  max-width: 100%;
  max-height: calc(100% - 25px);
  margin: auto;
  cursor: pointer;
`;

export const AddFileSlot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.5s ease;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  
  &:hover {
      background-color: rgba(1, 1, 1, .15);
  }
`;

export const PdfPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const DownloadButton = styled(ExButton)`
  position: absolute;
  top: 10px; 
  right: 10px; 
  border-radius: 5px;
  padding: 0;
  margin: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewFile = styled.div`
  height: 85vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
`;

export const PdfContainer = styled.div`
  height: 80vh;
  width: 80vh;
  overflow: hidden;
`;

export const ControlsContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 4px;
  padding: 4px;
`;

export const CarouselButton = styled(ExButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #e0e0e0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
