import { Grid } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { formatDecimal, isMobileDevice } from "../../../commons/utils";
import PieChart from "../../../charts/pie";
import moment from "moment";
import { GridCard } from "..";
import { ExCard } from "../../../charts/Card";
import AreaChart from "../../../charts/area";
import { Clock9, UserRoundX, UsersRound } from "lucide-react";

export const DashColaboradores = forwardRef((props: any, ref) => {
    const originData = useRef<any[]>([]);

    const refchartempresas = useRef<any>(null);
    const refchartgrupos = useRef<any>(null)
    const refchartmensal = useRef<any>(null);
    const refcardcolaboradores = useRef<any>(null);
    const refcarddesistencia = useRef<any>(null);
    const refcardaguardandoregistro = useRef<any>(null);
    const refFilter = useRef<any>({})

    useImperativeHandle(ref, () => ({
        setData
    }));

    const getNextMultipleOf100 = (value: number) => Math.ceil(value / 100) * 100;

    const getValue = (groupByKey: string | ((item: any) => string), item: any) => typeof groupByKey === 'function' ? groupByKey(item) : item[groupByKey] || 'Não Informado'

    const groupData = (data: any[], groupByKey: string | ((item: any) => string)) =>
        data.reduce((acc: Record<string, number>, item: any) => {
            const key = getValue(groupByKey, item);
            const value = parseFloat((item.total || 0).toFixed(2));
            acc[key] = (acc[key] || 0) + value;
            return acc;
        }, {});

    const populateChart = (refChart: any, data: any, options: { groupByKey: any; chartTitle: string; limit?: number; type?: 'Bar' | 'Pie'; labelFontSize?: string }, otherOptions?: any) => {
        const { groupByKey, chartTitle, limit = Infinity, type = 'Bar', labelFontSize = '16px' } = options;

        var fdataPointSelection

        if (groupByKey) {
            fdataPointSelection = (e: any, ct: any, { w }: any) => {
                const acceptValues = w.globals.selectedDataPoints[0]?.map((i: number) => w.globals.labels[i]).filter((e: any) => e)

                if (!acceptValues || acceptValues.length === 0)
                    delete refFilter.current[groupByKey]
                else
                    refFilter.current[groupByKey] = (e: any) => acceptValues.includes(getValue(groupByKey, e))

                prePopulate()
            }
        }

        const filterArr = Object.entries(refFilter.current)
        data = [...data].filter((e: any) => filterArr.every(([key, val]: any) => key === groupByKey || val(e)))

        const groupedData = groupData(data, groupByKey);

        let entries: [string, number][];

        if (typeof groupByKey === 'function')
            entries = Object.entries(groupedData).sort(([keyA], [keyB]) => {
                const dateA = moment(keyA, 'MM/YYYY');
                const dateB = moment(keyB, 'MM/YYYY');
                return dateA.diff(dateB);
            }).slice(0, limit);
        else
            entries = Object.entries(groupedData).sort(([, a], [, b]) => b - a).slice(0, limit);

        const labels = entries.map(([key]) => key);
        const values = entries.map(([, value]) => value);

        if (type === 'Bar') {
            refChart?.current.setOptions(labels, getNextMultipleOf100(Math.max(...values)), chartTitle, undefined, undefined, undefined, labelFontSize, fdataPointSelection, otherOptions);
            refChart?.current.setSeries([{ name: chartTitle, data: values }]);
        } else if (type === 'Pie') {
            refChart?.current.setLabels(labels);
            refChart?.current.setOptions(fdataPointSelection);
            refChart?.current.setSeries(values);
        }
    };

    const setCardValues = (ref: any, value: any) => {
        ref?.current.setValue(value);
    };

    const prePopulate = (filters?: { categoria?: any, subcategoria?: any, imobilizado?: any }) => {
        const filterArr = Object.entries(refFilter.current)
        const cardData = originData.current.filter((e: any) => filterArr.every(([key, val]: any) => val(e))).map((e: any) => ({ ...e, total: 1 }))
        const cardDataAtivo = cardData.filter((e: any) => e.colcancelado !== 1)

        setCardValues(refcardcolaboradores, cardDataAtivo.length);
        setCardValues(refcarddesistencia, formatDecimal(cardData.filter((e: any) => e.desistente === 1).length / cardDataAtivo.length * 100) + '%');
        setCardValues(refcardaguardandoregistro, cardDataAtivo.filter((e: any) => e.colstatusdescricao !== 'Registrado').length);

        populateChart(refchartmensal, cardDataAtivo, {
            groupByKey: (item: any) => moment(item.coladmissao).format('MM/YYYY'),
            chartTitle: 'Admissões por Mês'
        }, { skipFormat: true });

        populateChart(refchartgrupos, cardDataAtivo, {
            groupByKey: 'grudescricao',
            chartTitle: 'Grupos',
            type: 'Pie',
        });

        populateChart(refchartempresas, cardDataAtivo, {
            groupByKey: 'empresa',
            chartTitle: 'Empresas',
            type: 'Pie',
        });
    };

    const setData = (_data: any) => {
        originData.current = _data;
        prePopulate();
    };

    return (
        <div style={{ padding: isMobileDevice() ? '1rem' : '1rem 4rem' }}>
            <Grid container spacing={4}>
                <Grid item xs={isMobileDevice() ? 12 : 12}>
                    <Grid container spacing={2}>
                        <GridCard md={4}>
                            <ExCard ref={refcardcolaboradores} title='Colaboradores Ativos' icon={<UsersRound />} />
                        </GridCard>
                        <GridCard md={4}>
                            <ExCard ref={refcarddesistencia} title='Percentual de Desistência' icon={<UserRoundX />} />
                        </GridCard>
                        <GridCard md={4}>
                            <ExCard ref={refcardaguardandoregistro} title='Aguardando Registro' icon={<Clock9 />} />
                        </GridCard>
                    </Grid>
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 12}>
                    <AreaChart ref={refchartmensal} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 6}>
                    <div style={{ height: '450px' }}>
                        <PieChart ref={refchartempresas} title='Empresas' />
                    </div>
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 6}>
                    <div style={{ height: '450px' }}>
                        <PieChart ref={refchartgrupos} title='Setores' />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
});
