import { ListItemIcon, ListItemIconProps, ListItemText, ListItemTextProps, Drawer, DrawerProps, IconButtonProps, IconButton, Toolbar } from "@mui/material"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { lighten } from "polished";
import React from "react"
import { getCSSVariableValue, isMobileDevice } from "../../../commons/utils";
import { Menu } from "lucide-react";
import LogoBranca from '../../../images/logoPequenaBranca.png';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const ExMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'var(--side-bar-color)',
    marginLeft: !isMobileDevice() ? `-${DRAWER_WIDTH}px` : '0',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    }),
}));

export const ExAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface ExListItemIconProps extends ListItemIconProps {
    icon?: any;
}

export const ExListItemIcon = (props: ExListItemIconProps) => {
    return (
        <ListItemIcon
            sx={{
                color: 'inherit',
                marginRight: '-1.5rem',
                padding: '6px 0'
            }}
            {...props}
        >
            {React.cloneElement(props.icon, { style: { fontSize: '1.3rem', marginBottom: 3 } })}
        </ListItemIcon>
    )
}

interface ExListItemTextProps extends ListItemTextProps {
    label?: string;
    fontWeight?: string;
}

export const ExListItemText = (props: ExListItemTextProps) => {
    return (
        <ListItemText
            primaryTypographyProps={{
                color: '#fff',
                fontWeight: props.fontWeight ? props.fontWeight : 'medium',
                fontSize: '0.85rem',
                variant: 'body2'
            }}
            sx={{ my: 0.35, whiteSpace: 'pre-line' }}
            primary={props.label}
            {...props}
        />
    )
}

export const DRAWER_WIDTH = 255;

export const ExDrawer: React.FC<DrawerProps> = (props) => {
    return (
        <Drawer
            anchor={'left'}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    background: 'var(--side-bar-color)',
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    '&::-webkit-scrollbar': {
                        width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'var(--side-bar-color)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: lighten(.1, getCSSVariableValue('var(--side-bar-color)')),
                        borderRadius: '3px'
                    },
                },
            }}
            PaperProps={{
                style: { backgroundColor: 'var(--side-bar-color)', color: '#fff' }
            }}
            {...props}
        />
    )
}

export const ExIconButtonAuxSideBar: React.FC<IconButtonProps> = (props) => {
    return (
        <IconButton
            color="inherit"
            edge="start"
            sx={{ position: `${isMobileDevice() ? 'fixed' : 'relative'}`, left: `${isMobileDevice() ? '1rem' : '0'}` }}
            {...props}
        >
            <Menu />
        </IconButton>
    )
}

export const ExToolBarLogo = (props: any) => {
    return (
        <Toolbar variant='dense' >
            <div style={{ width: 150, paddingTop: '10px' }}>
                <img
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: `${window.innerWidth > 800 ? '-0.5rem' : ''}` }}
                    src={LogoBranca}
                    alt=''
                    width='130rem'
                />
            </div>
            {props.children}
        </Toolbar>
    )
}