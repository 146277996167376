import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { Modal } from "../../components/Modal";
import {
    GalleryContainer, FileContainer, Thumbnail, PdfPlaceholder, DownloadButton, AddFileSlot, Overlay,
    FileNameContainer, ContentWrapper, ViewFile, ControlsContainer
} from "./styles";
import { ExButton } from "../../components/ExButton";
import { ChevronLeft, ChevronRight, Download, Paperclip, Plus } from "lucide-react";
import { FileProps, selectAndUploadFile, uploadFileS3 } from "../../s3/aws";
import { toastCustomError } from "../../commons/utils";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

export interface SlotProps {
    url: string | null;
    allowsPdf?: boolean;
    allowsImage?: boolean;
    name: string | null;
}

export interface GalleryHandler {
    openModal: (slots: SlotProps[], title: string, id: string, allowNewImages?: boolean, usucodigo?: number,) => void;
}

interface GalleryProps {
    onUploadFile?: (id: string, file: FileProps, usucodigo?: number) => Promise<any>;
}

export const Gallery = forwardRef<GalleryHandler, GalleryProps>(({ onUploadFile }, ref) => {
    const auth = useAuth();
    const modalRef = useRef<any>();

    const [slots, setSlots] = useState<SlotProps[]>(Array(4).fill({ url: null, allowsPdf: true, allowsImage: true, name: null }));

    const [selectedFile, setSelectedFile] = useState<SlotProps | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [customTitle, setCustomTitle] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [usucodigo, setUsuCodigo] = useState<number>(0);
    const [allowNewImages, setAllowNewImages] = useState<boolean>(false);

    function updateSlots(slotsList: SlotProps[], newslot: boolean) {
        if (!newslot)
            return setSlots(slotsList);

        let updatedSlots = slotsList;
        updatedSlots.push({ url: null, allowsPdf: true, allowsImage: true, name: null });
        setSlots(updatedSlots);
    }

    function openModal(slotsList: SlotProps[], title: string, id: string, newimages: boolean = false, usucodigo?: number) {
        setCustomTitle(title);
        setId(id);
        setUsuCodigo(usucodigo ?? 0);
        setSelectedFile(null);
        setSelectedIndex(-1);
        setAllowNewImages(newimages);
        updateSlots(slotsList, newimages);
        modalRef.current?.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    const handleOpen = (slot: SlotProps, index: number) => {
        setSelectedFile(slot);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setSelectedFile(null);
        setSelectedIndex(-1);
    };

    const handleDownload = (url: string) => {
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleNavigation = (direction: "next" | "previous") => {
        const _slots = slots.filter((slot) => slot.url);

        if (direction === "next" && selectedIndex < _slots.length - 1) {
            const nextSlot = _slots[selectedIndex + 1];
            setSelectedFile(nextSlot);
            setSelectedIndex(selectedIndex + 1);
        } else if (direction === "previous" && selectedIndex > 0) {
            const prevSlot = _slots[selectedIndex - 1];
            setSelectedFile(prevSlot);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") handleNavigation("previous");
            if (event.key === "ArrowRight") handleNavigation("next");
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);

    async function internalUploadFile(index: number, file: FileProps) {
        const updatedSlots = [...slots];
        const slotName = updatedSlots[index]?.name || file.filename;

        updatedSlots[index] = {
            url: file.url,
            name: slotName,
        };

        updateSlots(updatedSlots, allowNewImages);

        if (onUploadFile) {
            await onUploadFile(id, { ...file, filename: slotName }, usucodigo);
        }
    }

    async function selectFile(index: number) {
        try {
            const result: FileProps = await selectAndUploadFile(slots[index], auth, 'dp-files');
            await internalUploadFile(index, result);
        } catch (error) {
            toastCustomError('Erro ao enviar o arquivo');
        }
    }

    const handleDrop = async (files: FileList, index: number) => {
        if (files.length > 0) {
            const file = files[0];
            const slot = slots[index];

            const isAllowedImage = slot.allowsImage && file.type.startsWith('image');
            const isAllowedPdf = slot.allowsPdf && (file.type === 'application/pdf');

            if (!(isAllowedImage || isAllowedPdf)) {
                toastCustomError("Tipo de arquivo não permitido para este slot");
                return;
            }

            const result: FileProps = await uploadFileS3(file, auth, 'dp-files');
            await internalUploadFile(index, result);
        }
    };

    const handleDragOver = (event: React.DragEvent) => { event.preventDefault() };

    const handleDropSlot = (event: React.DragEvent, index: number) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleDrop(files, index);
    };

    return (
        <Modal ref={modalRef} backdrop={true} title={"Arquivos" + (customTitle ? '- ' + customTitle : '')} size="xl" icon={<Paperclip />}>
            <GalleryContainer>
                {slots.map((slot, index) => (
                    <FileContainer key={index} onDragOver={handleDragOver}>
                        {slot.url ? (
                            slot.url.toLowerCase().endsWith(".pdf") ?
                                (
                                    <PdfPlaceholder onClick={() => handleOpen(slot, index)}>
                                        <img src="https://resources.exaltsd.com.br/dp-files/6e37fb36-d099-47d9-9efd-2dc6808b0275.png" alt="icon_pdf" width='50px' />
                                    </PdfPlaceholder>
                                ) :
                                (
                                    <Thumbnail src={slot.url} alt={`Image ${index}`} onClick={() => handleOpen(slot, index)} />
                                )
                        ) : (
                            <AddFileSlot onClick={() => selectFile(index)} onDragOver={handleDragOver} onDrop={(event) => handleDropSlot(event, index)}>
                                <Plus size={40} style={{ color: '#888' }} />
                                <div>
                                    Clique aqui ou arraste o arquivo para este slot <br />
                                    <b>{'(' + (slot.allowsImage && slot.allowsPdf ? 'Imagem ou PDF' : slot.allowsImage ? 'Imagem' : 'PDF') + ')'}</b>
                                </div>
                            </AddFileSlot>
                        )}
                        {slot.url && <DownloadButton onClick={() => slot.url && handleDownload(slot.url)} iconcenter={<Download size={20} />} />}
                        {slot.name && <FileNameContainer>{slot.name}</FileNameContainer>}
                    </FileContainer>
                ))}
            </GalleryContainer>

            {/*Carrossel*/}
            {selectedFile && selectedFile.url && (
                <Overlay onClick={handleClose}>
                    <ContentWrapper onClick={(e) => e.stopPropagation()}>
                        <ViewFile style={{ width: selectedFile.url.toLowerCase().endsWith(".pdf") ? '90vh' : '100%' }}>
                            {selectedFile.url.toLowerCase().endsWith(".pdf") ?
                                (
                                    <iframe
                                        src={`https://docs.google.com/viewer?url=${selectedFile.url}&embedded=true`}
                                        width="100%"
                                        height="100%"
                                        title={`Arquivo PDF: ${selectedFile.url}`}
                                    />
                                ) :
                                (
                                    <img
                                        src={selectedFile.url!}
                                        alt={selectedFile.url!}
                                        style={{ maxHeight: '100%' }}
                                    />
                                )
                            }
                        </ViewFile>
                        <ControlsContainer>
                            <ExButton onClick={() => handleNavigation("previous")} disabled={selectedIndex === 0}>
                                <ChevronLeft />
                            </ExButton>
                            <ExButton onClick={() => handleNavigation("next")} disabled={selectedIndex === slots.filter((slot) => slot.url).length - 1}>
                                <ChevronRight />
                            </ExButton>
                        </ControlsContainer>
                    </ContentWrapper>
                </Overlay>
            )}
        </Modal>
    );
});

