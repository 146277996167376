import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { formatDataCompare, getLocalStorage, isMobileDevice, setLocalStorage, toastError } from "../../../../commons/utils";
import { Grid } from "@mui/material";
import { Input } from "../../../../components/Input";
import { ExSelect } from "../../../../components/ExSelect";
import { model_fornecedor, model_imobilizado } from "../../../../models";
import moment from "moment";

export const FiltroRequisicoes = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const refdatai = useRef<any>();
    const refdataf = useRef<any>();
    const reffornecedor = useRef<any>();
    const refimobilizado = useRef<any>();

    const [data, setData] = useState<any>();

    function refresh() {
        change(true);
    }

    useImperativeHandle(ref, () => ({
        refresh
    }))

    async function getDados(refresh: boolean, iData?: any, fData?: any, fornecedores?: any, imobilizados?: any) {
        let _data: any;

        if (!refresh && data)
            _data = data;
        else {
            await auth.get('/v1/requisicaokanban')
                .then((response: any) => {
                    setData(response.data);
                    _data = response.data;
                })
                .catch((error: any) => {
                    toastError(error);
                })
        }

        let _iData = iData;
        let _fData = fData;
        let _fornecedores = fornecedores;
        let _imobilizados = imobilizados;

        if (!_iData && (_iData !== ''))
            _iData = refdatai.current.getValue();

        if (!_fData && (_fData !== ''))
            _fData = refdataf.current.getValue();

        if (!_fornecedores)
            _fornecedores = reffornecedor.current.getValue();

        if (!_imobilizados)
            _imobilizados = refimobilizado.current.getValue();

        if (_iData && (_iData !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.reqdata) >= _iData);

        if (_fData && (_fData !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.reqdata) <= _fData);

        if (_fornecedores && (_fornecedores.length > 0)) {
            const fornecedoresValues = _fornecedores.map((fornecedor: any) => fornecedor.value);
            _data = _data.filter((item: any) => fornecedoresValues.includes(item.forid));
        }

        if (_imobilizados && (_imobilizados.length > 0)) {
            const imobilizadosValues = _imobilizados.map((imobilizado: any) => imobilizado.value);
            _data = _data.filter((item: any) => imobilizadosValues.includes(item.imobid));
        }

        // Armazena os filtros 
        setLocalStorage('iDataKanbanRequisicoes', _iData ?? null);
        setLocalStorage('fDataKanbanRequisicoes', _fData ?? null);

        return _data;
    }

    function change(refresh: boolean, iData?: any, fData?: any, fornecedores?: any, imobilizados?: any) {
        getDados(refresh, iData, fData, fornecedores, imobilizados).then((data: any) => {
            if (props.onGetData)
                props.onGetData(data);
        })
    }

    function changeDataInicial(_newValue: any) { change(false, _newValue) }
    function changeDataFinal(_newValue: any) { change(false, null, _newValue) }
    function changeFornecedor(_newValue: any) { change(false, null, null, _newValue) }
    function changeImobilizado(_newValue: any) { change(false, null, null, null, _newValue) }

    useEffect(() => {
        change(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={12}>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={isMobileDevice() ? 6 : 2}>
                    <Input ref={refdatai} id="iDataKanbanRequisicoes" type="date" label="Retorno Inicial" onAfterChange={changeDataInicial} defaultValue={getLocalStorage('iDataKanbanRequisicoes') ? getLocalStorage('iDataKanbanRequisicoes') : moment().format('YYYY-MM-01')} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 6 : 2}>
                    <Input ref={refdataf} id="fDataKanbanRequisicoes" type="date" label="Retorno Final" onAfterChange={changeDataFinal} defaultValue={moment().format('YYYY-MM-DD')} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 3}>
                    <ExSelect ref={reffornecedor} id='fornecedor' label='Fornecedor' model={model_fornecedor} onAfterChange={changeFornecedor} isMulti />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 3}>
                    <ExSelect ref={refimobilizado} id='imobilizado' label='Imobilizado' model={model_imobilizado} onAfterChange={changeImobilizado} isMulti />
                </Grid>
            </Grid>
        </Grid>
    )
})