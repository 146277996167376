import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { formatDecimal, isMobileDevice } from '../commons/utils';
import { ExPaper } from '../components/Paper';

interface IState {
    options?: any;
    series?: any;
    height?: any;
}

class AreaChart extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    toolbar: { show: !isMobileDevice() },
                    zoom: { show: false },
                    background: 'transparent',
                },
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        dataLabels: { position: 'top' }
                    }
                },
                xaxis: { labels: { style: { colors: '#fff' } } },
                yaxis: {
                    labes: {
                        style: { colors: '#fff' },
                        formatter: (val: any) => {
                            if (val >= 1000) { return `${(val / 1000).toFixed(1)} mil` }
                            return val.toFixed(2);
                        }
                    },
                    tickAmount: 4,
                    min: 0
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        gradientToColors: ['#F68D2E'],
                        shadeIntensity: 1,
                        opacityFrom: 0.1,
                        opacityTo: 0.7,
                        stops: [0, 100],
                    }
                },
                tooltip: { theme: 'dark' },
                theme: { mode: 'dark' },
                stroke: { curve: 'smooth' },
                title: {
                    align: 'center',
                    style: {
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: "Montserrat, Segoe UI",
                    }
                }
            }

        }
    }

    setSeries = (series: any) => {
        this.setState({
            series
        });
    }

    setOptions = (labels: any[] | [], maxValue: any | null, title: any, percent?: boolean, colors?: any, legend?: boolean, fontSize?: any, dataPointSelectin?: any, otherOptions?: any) => {
        this.setState({
            options: {
                ...this.state.options,
                colors: colors ? colors : ['var(--primary-color)'],
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: labels
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    max: maxValue ? maxValue : null,
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val: any) => {
                        if (otherOptions?.skipFormat === true) return val;
                        if (val === 0) return '';
                        return formatDecimal(val) + (percent ? '%' : '');
                    },
                    style: { fontSize: isMobileDevice() ? '12px' : (fontSize ? fontSize : '20px') },
                },
                title: {
                    ...this.state.options.title,
                    text: title
                },
                legend: {
                    show: legend
                },
            }
        });
    }

    render() {
        return (
            <ExPaper>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    width={'100%'}
                    height={'450px'}
                    align="center"
                    type='area'
                />
            </ExPaper>
        )
    }
}

export default AreaChart;
