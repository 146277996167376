import { useEffect, useRef, useState } from "react";
import { ExButton } from "../../../components/ExButton";
import { ExSwitch } from "../../../components/ExSwitch";
import { Col, Row } from "react-bootstrap";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { GruposStatus } from "./GruposStatus";
import { ChevronDown, ChevronRight, SlidersHorizontal } from "lucide-react";

interface Grupo {
    usucodigo: number;
    gruid: number;
    gruidpai: number | null;
    grudescricao: string;
    usugruler: number;
    usugruincluir: number;
    usugrualterar: number;
    usugruexcluir: number;
}

export const TreeGroup = (props: any) => {
    const refstatus = useRef<any>();

    const [gruposCadastrados, setGruposCadastrados] = useState<Grupo[]>([]);
    const [gruposProjetados, setGruposProjetados] = useState<Grupo[]>([]);
    const [expandedNodes, setExpandedNodes] = useState<Set<number>>(new Set());

    const auth = useAuth();

    useEffect(() => {
        getDados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setExpandedNodes(initializeExpandedNodes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gruposProjetados]);

    async function getDados() {
        try {
            const responseProjetados: any = await auth.get('/v1/usuariogrupoprojetado');
            const filteredProjetados = responseProjetados.data.filter((item: any) => item.usucodigo === props.usucodigo);
            setGruposProjetados(filteredProjetados);

            const responseCadastrados: any = await auth.get('/v1/usuariogrupo');
            const filteredCadastrados = responseCadastrados.data.filter((item: any) => item.usucodigo === props.usucodigo);
            setGruposCadastrados(filteredCadastrados);
        } catch (error) {
            console.log(error);
        }
    }

    async function updateStatus(_body: any) {
        let body = {
            gruid: _body.gruid,
            usucodigo: _body.usucodigo,
            usugruler: _body.usugruler,
            usugruincluir: _body.usugruincluir,
            usugrualterar: _body.usugrualterar,
            usugruexcluir: _body.usugruexcluir,
        };

        try {
            await auth.post('/v1/usuariogrupoupsert', body);
            await getDados();
        } catch (error) {
            console.log(error);
        }
    }

    const initializeExpandedNodes = (): Set<number> => {
        return new Set(gruposProjetados.map(grupo => grupo.gruid));
    };

    const toggleNode = (id: number) => {
        setExpandedNodes(prev => {
            const newExpandedNodes = new Set(prev);
            if (newExpandedNodes.has(id)) {
                newExpandedNodes.delete(id);
            } else {
                newExpandedNodes.add(id);
            }
            return newExpandedNodes;
        });
    };

    function existGrupoCadastrado(gruid: number) {
        return gruposCadastrados.some(grupo => grupo.gruid === gruid);
    }

    const renderGrupoTree = (projetados: Grupo[], gruidpai: number | null, level: number = 0): JSX.Element[] => {
        return projetados
            .filter(grupo => grupo.gruidpai === gruidpai)
            .map(grupo => {
                const isExpanded = expandedNodes.has(grupo.gruid);
                const childs = gruposProjetados?.filter(g => g.gruidpai === grupo.gruid);
                const hasChildren = childs && childs.length > 0;

                return (
                    <div key={grupo.gruid} style={{ padding: '8px 0px' }}>
                        <Row style={{ opacity: existGrupoCadastrado(grupo.gruid) ? '100%' : '25%' }}>
                            <Col>
                                <div style={{ marginLeft: hasChildren ? level * 38 : (level * 38) }}>
                                    {hasChildren && (
                                        <ExButton
                                            onClick={() => toggleNode(grupo.gruid)}
                                            style={{ display: '', marginRight: '8px', borderRadius: '50%', width: '30px', height: '30px', padding: 0 }}
                                            iconcenter={isExpanded ? <ChevronDown width={'16px'} /> : <ChevronRight width={'16px'} />}
                                        />
                                    )}
                                    <span>{grupo.grudescricao} - {grupo.gruid}</span>
                                </div>
                            </Col>
                            <Col className="text-end"><ExSwitch checked={grupo.usugruler === 1} onChange={(e: any) => updateStatus({ ...grupo, usugruler: e.target.checked ? 1 : 0 })} /></Col>
                            <Col className="text-end"><ExSwitch checked={grupo.usugruincluir === 1} onChange={(e: any) => updateStatus({ ...grupo, usugruincluir: e.target.checked ? 1 : 0 })} /></Col>
                            <Col className="text-end"><ExSwitch checked={grupo.usugrualterar === 1} onChange={(e: any) => updateStatus({ ...grupo, usugrualterar: e.target.checked ? 1 : 0 })} /></Col>
                            <Col className="text-end"><ExSwitch checked={grupo.usugruexcluir === 1} onChange={(e: any) => updateStatus({ ...grupo, usugruexcluir: e.target.checked ? 1 : 0 })} /></Col>
                            <Col className="text-end">
                                <ExButton
                                    onClick={() => refstatus.current.openModal(grupo.gruid, grupo.usucodigo, grupo.grudescricao)}
                                    style={{ display: '', marginRight: '8px', borderRadius: '6px', width: '30px', height: '30px', padding: 0 }}
                                    iconcenter={<SlidersHorizontal width={'18px'} />}
                                />
                            </Col>
                        </Row>
                        {isExpanded && renderGrupoTree(projetados, grupo.gruid, level + 1)}
                    </div>
                );
            });
    };

    return (
        <div style={{ padding: '5px 0' }}>
            <Row style={{ fontWeight: 'bold', fontSize: '16px', padding: '8px 0' }}>
                <Col>Grupo</Col>
                <Col className="text-end">Ler</Col>
                <Col className="text-end">Incluir</Col>
                <Col className="text-end">Alterar</Col>
                <Col className="text-end">Excluir</Col>
                <Col className="text-end">Configurar Status</Col>
            </Row>
            <GruposStatus ref={refstatus} />
            {renderGrupoTree(gruposProjetados, null)}
        </div>
    );
};
