import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { Col, Row } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { ExSwitch } from "../../../../components/ExSwitch";
import { SlidersHorizontal } from "lucide-react";

interface PermissionSwitchProps {
    isChecked: boolean;
    isOpacity: boolean;
    onChange: () => void;
}

export const GruposStatus = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const refmodal = useRef<any>();

    const [statusProjetos, setStatusProjetados] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>([]);
    const [statusTipoMovProj, setStatusTipoMovProj] = useState<any[]>([]);
    const [statusTipoMov, setStatusTipoMov] = useState<any[]>([]);

    function openModal(_gruid: any, _usucodigo: any) {
        setStatusProjetados([]);
        setStatus([]);
        setStatusTipoMovProj([]);
        setStatusTipoMov([]);

        getDados(_gruid, _usucodigo);
        refmodal.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    async function getDados(_gruid: any, _usucodigo: any) {
        getDadosSt(_gruid, _usucodigo)
        getDadosMv(_gruid, _usucodigo)
    }

    async function getDadosSt(_gruid: any, _usucodigo: any) {
        try {
            var response: any = await auth.get('/v1/status?gruid=' + _gruid + '&statativo=1');
            const status = response.data

            response = await auth.get('/v1/usuariogrupostatuspermissaodesc?gruid=' + _gruid + '&usucodigo=' + _usucodigo);

            var statusPerm = response.data
            statusPerm = statusPerm.filter((e: any) => status.find((el: any) => el.statid === e.statid))
            statusPerm = statusPerm.sort((a: any, b: any) => status.find((el: any) => el.statid === a.statid).statsequencia - status.find((el: any) => el.statid === b.statid).statsequencia)
            setStatusProjetados(statusPerm);

            response = await auth.get('/v1/usuariogrupostatus?gruid=' + _gruid + '&usucodigo=' + _usucodigo);
            setStatus(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getDadosMv(_gruid: any, _usucodigo: any) {
        try {
            var response: any = await auth.get('/v1/tipomovimento?gruid=' + _gruid + '&tipmovativo=1');
            const tipomovimento = response.data

            response = await auth.get('/v1/usuariogrupotipomovpermissaodesc?gruid=' + _gruid + '&usucodigo=' + _usucodigo);

            var tipMovPerm = response.data
            tipMovPerm = tipMovPerm.filter((e: any) => tipomovimento.find((el: any) => el.tipmovcontador === e.tipmovcontador))
            tipMovPerm = tipMovPerm.sort((a: any, b: any) => a.tipmovcontador - b.tipmovcontador)
            setStatusTipoMovProj(tipMovPerm);

            response = await auth.get('/v1/usuariogrupotipomov?gruid=' + _gruid + '&usucodigo=' + _usucodigo);
            setStatusTipoMov(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function updateStatus(_body: any) {
        let body = {
            gruid: _body.gruid,
            usucodigo: _body.usucodigo,
            statid: _body.statid,
            usugrustler: _body.usugrustler,
            usugrustincluir: _body.usugrustincluir,
            usugrustalterar: _body.usugrustalterar,
            usugrustexcluir: _body.usugrustexcluir,
        };

        try {
            await auth.post('/v1/usuariogrupostatus', body);
            await getDadosSt(_body.gruid, _body.usucodigo);
        } catch (error) {
            console.log(error);
        }
    }

    async function updateStatusMov(_body: any) {
        let body = {
            gruid: _body.gruid,
            usucodigo: _body.usucodigo,
            tipmovcontador: _body.tipmovcontador,
            usugrumvler: _body.usugrumvler,
            usugrumvincluir: _body.usugrumvincluir,
            usugrumvalterar: _body.usugrumvalterar,
            usugrumvexcluir: _body.usugrumvexcluir,
        };

        try {
            await auth.post('/v1/usuariogrupotipomov', body);
            await getDadosMv(_body.gruid, _body.usucodigo);
        } catch (error) {
            console.log(error);
        }
    }

    const PermissionSwitch: React.FC<PermissionSwitchProps> = ({ isChecked, isOpacity, onChange }) => (
        <Col className="text-end" style={{ opacity: isOpacity ? '25%' : '100%' }}>
            <ExSwitch checked={isChecked} onChange={onChange} />
        </Col>
    );

    const PermissionSwitchSt = ({ _status, originStatus, tipo }: { _status: any, originStatus?: any, tipo: 'ler' | 'incluir' | 'alterar' | 'excluir' }) => {
        const onChange = () => {
            let obj = { ...originStatus }
            obj['usugrust' + tipo] = (!originStatus || originStatus['usugrust' + tipo] === null) ? 1 : originStatus['usugrust' + tipo] === 1 ? 0 : null
            updateStatus(obj)
        }

        return (
            <PermissionSwitch
                isChecked={_status['usugrust' + tipo] === 1}
                isOpacity={!originStatus || originStatus['usugrust' + tipo] === null}
                onChange={onChange}
            />
        )
    }

    const PermissionSwitchMv = ({ _status, originStatus, tipo }: { _status: any, originStatus?: any, tipo: 'ler' | 'incluir' | 'alterar' | 'excluir' }) => {
        const onChange = () => {
            let obj = { ...originStatus }
            obj['usugrumv' + tipo] = (!originStatus || originStatus['usugru' + tipo] === null) ? 1 : originStatus['usugru' + tipo] === 1 ? 0 : null
            updateStatusMov(obj)
        }

        return (
            <PermissionSwitch
                isChecked={_status['usugru' + tipo] === 1}
                isOpacity={!originStatus || originStatus['usugru' + tipo] === null}
                onChange={onChange}
            />
        )
    }

    const renderGrupoStatus = (statusProjetados: any[]): JSX.Element[] => {
        return statusProjetados.map(_status => {
            let originStatus = status.find(status2 => status2.statid === _status.statid);

            if (!originStatus)
                originStatus = {
                    gruid: _status.gruid,
                    usucodigo: _status.usucodigo,
                    statid: _status.statid,
                    usugrustler: null,
                    usugrustincluir: null,
                    usugrustalterar: null,
                    usugrustexcluir: null
                }

            return (
                <div key={_status.statid} style={{ padding: '8px 0px', fontSize: '14px' }}>
                    <Row>
                        <Col><span>{_status.statdescricao}</span></Col>
                        <PermissionSwitchSt _status={_status} originStatus={originStatus} tipo="ler" />
                        <PermissionSwitchSt _status={_status} originStatus={originStatus} tipo="incluir" />
                        <PermissionSwitchSt _status={_status} originStatus={originStatus} tipo="alterar" />
                        <PermissionSwitchSt _status={_status} originStatus={originStatus} tipo="excluir" />
                    </Row>
                </div>
            );
        });
    };

    const renderGrupoStatusTipoMov = (statusProjetados: any[]): JSX.Element[] => {
        return statusProjetados.map(_status => {
            let originStatus = statusTipoMov.find(status2 => status2.tipmovcontador === _status.tipmovcontador);

            if (!originStatus)
                originStatus = {
                    gruid: _status.gruid,
                    usucodigo: _status.usucodigo,
                    tipmovcontador: _status.tipmovcontador,
                    usugruler: null,
                    usugruincluir: null,
                    usugrualterar: null,
                    usugruexcluir: null
                }
            else {
                originStatus.usugruler = originStatus.usugrumvler
                originStatus.usugruincluir = originStatus.usugrumvincluir
                originStatus.usugrualterar = originStatus.usugrumvalterar
                originStatus.usugruexcluir = originStatus.usugrumvexcluir
            }


            return (
                <div key={_status.tipmovcontador} style={{ padding: '8px 0px', fontSize: '14px' }}>
                    <Row>
                        <Col><span>{_status.tipmovdescricao}</span></Col>
                        <PermissionSwitchMv _status={_status} originStatus={originStatus} tipo="ler" />
                        <PermissionSwitchMv _status={_status} originStatus={originStatus} tipo="incluir" />
                        <PermissionSwitchMv _status={_status} originStatus={originStatus} tipo="alterar" />
                        <PermissionSwitchMv _status={_status} originStatus={originStatus} tipo="excluir" />
                    </Row>
                </div>
            );
        });
    };

    return (
        <Modal
            ref={refmodal}
            title='Configuração de Status'
            size={'xl'}
            icon={<SlidersHorizontal />}
        >
            <div style={{ marginTop: '6px', fontSize: '24px' }}>
                <span>Requisições</span>
            </div>
            <div style={{ padding: '5px 0' }}>
                <Row style={{ fontWeight: 'bold', padding: '8px 0' }}>
                    <Col>Status</Col>
                    <Col className="text-end">Ler</Col>
                    <Col className="text-end">Incluir</Col>
                    <Col className="text-end">Alterar</Col>
                    <Col className="text-end">Excluir</Col>
                </Row>
                {renderGrupoStatus(statusProjetos)}
            </div>
            <div style={{ marginTop: '6px', fontSize: '12px' }}>
                <span>A permissão "ler" diz respeito apenas aos registros dos demais usuários. O usuário sempre poderá consultar os próprios registros.</span>
            </div>

            <div style={{ marginTop: '48px', fontSize: '24px' }}>
                <span>Estoques</span>
            </div>
            <div style={{ padding: '5px 0' }}>
                <Row style={{ fontWeight: 'bold', padding: '8px 0' }}>
                    <Col>Status</Col>
                    <Col className="text-end">Ler</Col>
                    <Col className="text-end">Incluir</Col>
                    <Col className="text-end">Alterar</Col>
                    <Col className="text-end">Excluir</Col>
                </Row>
                {renderGrupoStatusTipoMov(statusTipoMovProj)}
            </div>
        </Modal>
    );
});
