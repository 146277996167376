import { useRef } from "react";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { ExSwitch } from "../../../components/ExSwitch";
import { model_tipo_movimento } from "../../../models";
import { TipoMovimentoValores, TipoMovimentoValoresRef } from "./TipoMovimentoValores";
import { GitPullRequestArrow } from "lucide-react";

export const TipoMovimento = () => {
    const refUnidMedida = useRef<TipoMovimentoValoresRef>(null);

    const columns = [
        { field: 'tipmovcontador', header: 'Código' },
        { field: 'tipmovdescricao', header: 'Descrição' },
        { field: 'tipmovacao', header: 'Ação' },
        { field: 'tipmovperda', header: 'Perda', body: (e: any) => <ExSwitch checked={e.tipmovperda === 1} /> },
        { field: 'tipmovinformapreco', header: 'Informa Preço', body: (e: any) => <ExSwitch checked={e.tipmovinformapreco === 1} /> },
        { field: 'tipmovfinanceiro', header: 'Gera Financeiro', body: (e: any) => <ExSwitch checked={e.tipmovfinanceiro === 1} /> },
        { field: 'tipmovduasetapas', header: 'Duas Etapas', body: (e: any) => <ExSwitch checked={e.tipmovduasetapas === 1} /> },
        { field: 'tipmovativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    function additionalFunctions(row: any) {
        let _result = [];

        _result.push({ click: () => refUnidMedida.current?.openModal(row.tipmovcontador), icon: <GitPullRequestArrow size={20} />, color: 'var(--primary-color)' })

        return _result;
    }

    return (
        <>
            <ConsultaPadrao model={model_tipo_movimento} columns={columns} sortField="tipmovcontador" functions={additionalFunctions} />
            <TipoMovimentoValores ref={refUnidMedida} />
        </>
    )
} 