import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { formatDecimal, isMobileDevice } from '../../commons/utils';
import { ExPaper } from '../../components/Paper';

interface IState {
    options?: any// ApexCharts.ApexOptions;
    series?: any;
    height?: any;
}

class BarChart extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: { show: !isMobileDevice() },
                    zoom: { show: false },
                    background: 'transparent',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '80%',
                        endingShape: 'rounded',
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        dataLabels: {
                            position: 'top'
                        }
                    }
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: '#fff'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#fff'
                        },
                        formatter: (val: any) => {
                            if (val >= 1000) { return `${(val / 1000).toFixed(1)} mil` }
                            return val.toFixed(2);
                        }
                    },
                    tickAmount: 4,
                    min: 0
                },
                fill: { opacity: 1 },
                tooltip: { theme: 'dark' },
                theme: {
                    mode: 'dark',
                },
                title: {
                    align: 'center',
                    style: {
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: "Montserrat, Segoe UI",
                    }
                },
            }

        }
    }

    setSeries = (series: any) => {
        this.setState({
            series
        });
    }

    setOptions = (labels: any[] | [], maxValue: any | null, title: any, percent?: boolean, colors?: any, legend?: boolean, fontSize?: any, dataPointSelection?: any) => {
        this.setState(prev => ({
            options: {
                ...prev.options,
                chart: {
                    ...prev.options.chart,
                    events: {
                        dataPointSelection
                    }
                },
                colors: colors ? colors : ['var(--primary-color)'],
                xaxis: {
                    ...prev.options.xaxis,
                    categories: labels
                },
                yaxis: {
                    ...prev.options.yaxis,
                    max: maxValue ? maxValue : null,
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val: any) => formatDecimal(val) + (percent ? '%' : ''),
                    style: { fontSize: isMobileDevice() ? '12px' : (fontSize ? fontSize : '20px') },
                },
                title: {
                    ...prev.options.title,
                    text: title
                },
                legend: {
                    show: legend
                },
                states: {
                    active: {
                        allowMultipleDataPointsSelection: true,
                    }
                }
            }
        }));
    }

    setStacked = (percent?: boolean) => {
        this.setState(prev => ({
            options: {
                ...prev.options,
                chart: {
                    ...prev.options.chart,
                    stacked: true,
                    stackType: percent ? '100%' : undefined
                }
            }
        }));
    }

    setFillRule = (fill: any) => {
        this.setState(prev => ({
            options: {
                ...prev.options,
                fill
            }
        }));
    }

    render() {
        return (
            <ExPaper>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    width={'100%'}
                    height={'400px'}
                    type='bar'
                    align="center"
                />
            </ExPaper>
        )
    }
}

export default BarChart;
