import { Grid } from "@mui/material";
import { isMobileDevice } from "../../commons/utils";
import { Column } from "primereact/column";
import { Tab, Tabs } from "react-bootstrap";
import { DashEntradas } from "./Entradas";
import { DashClassificacoes } from "./Classificações";
import './styles.css';
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { DashVendas } from "./Saidas";
import FiltroCultura from "../FiltroCultura";
import { DashFinanceiroGeral } from "./FinanceiroGeral";
import { DashRequisicoes } from "./Requisicoes";
import { DashTransformacoes } from "./Transformacoes";
import { DashColaboradores } from "./Colaboradores";
import { DashPlantio } from "./Plantio";

export const GridCard = (props: any) => {
    return (
        <Grid item xs={isMobileDevice() ? 12 : props.md ? props.md : 3}>
            {props.children}
        </Grid>
    )
}

export const Columns = (columns: any) => {
    return columns?.map((col: any, i: number) => (
        <Column
            key={col.field}
            field={col.field}
            align={isMobileDevice() || col.dataType === 'numeric' ? 'right' : 'left'}
            header={col.header}
            body={col.body}
            sortable={true}
            style={{ ...col.style, fontSize: '12px', marginBottom: `${i + 1 === columns.length ? '0px' : '-14px'}` }}
        />
    ));
};

export const Dashboard = () => {
    const auth = useAuth();

    const [culturaChecked, setCulturaChecked] = useState<any>({});
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const refEntrada = useRef<any>(null);
    const refClassificacao = useRef<any>(null);
    const refVenda = useRef<any>(null);
    const refTransformacao = useRef<any>(null)
    const refPlantio = useRef<any>(null)
    const refFinanceiroGeral = useRef<any>(null);
    const refRequisicoes = useRef<any>(null);
    const refColaboradores = useRef<any>(null);

    async function getMovimentos() {
        try {
            if (auth.useAppEstoques) {
                const entradaResponse: any = await auth.get('/v1/movimentosentrada');
                const classificacaoResponse: any = await auth.get('/v1/movimentosclassificacao');
                const transformacaoResponse: any = await auth.get('/v1/movimentostransformacao');
                const plantioResponse: any = await auth.get('/v1/movimentosplantio');
                const saidaResponse: any = await auth.get('/v1/movimentossaida');
                const saldoResponse: any = await auth.get('/v1/movimentosaldoporproduto');
                const crByVencimento: any = await auth.get('/v1/crbyvencimento');
                const contasReceber: any = await auth.get('/v1/contasrecebercrm');

                const entradaProducao = entradaResponse.data.filter((e: any) => e.pltipo === 0)

                refEntrada.current.setData(entradaProducao);
                refClassificacao.current.setData(entradaProducao, classificacaoResponse.data);
                refVenda.current.setData(entradaProducao, saidaResponse.data);
                refVenda.current.setSaldo(saldoResponse.data);
                refTransformacao.current.setData(entradaResponse.data, transformacaoResponse.data);
                refPlantio.current.setData(plantioResponse.data);
                refFinanceiroGeral.current.setData(crByVencimento.data, contasReceber.data);

                setData(entradaResponse.data.length ? entradaResponse.data : saidaResponse.data);
            }

            if (auth.useAppRequisicoes) {
                const requisicoes: any = await auth.get('/v1/requisicaodash');
                refRequisicoes.current.setData(requisicoes.data)
            }

            if (auth.useAppDP) {
                const colaboradores: any = await auth.get('/v1/colaboradordash');
                refColaboradores.current.setData(colaboradores.data)
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            toast.error('Erro ao executar a operação.');
        }
    }

    useEffect(() => {
        getMovimentos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tabs style={{ padding: isMobileDevice() ? '1rem' : '.8rem 2rem' }}>
            {auth.useAppEstoques &&
                <Tab eventKey="entradas" title="Entradas" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                    <DashEntradas ref={refEntrada} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
                </Tab>
            }
            {auth.useAppEstoques &&
                <Tab eventKey="classificacao" title="Classificação" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                    <DashClassificacoes ref={refClassificacao} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
                </Tab>
            }
            {auth.useAppEstoques &&
                <Tab eventKey="vendas" title="Vendas" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                    <DashVendas ref={refVenda} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
                </Tab>
            }
            {auth.useAppEstoques &&
                <Tab eventKey="transformacao" title="Debulha" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                    <DashTransformacoes ref={refTransformacao} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
                </Tab>
            }
            {auth.useAppEstoques &&
                <Tab eventKey="plantio" title="Plantio" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                    <DashPlantio ref={refPlantio} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
                </Tab>
            }
            {auth.useAppEstoques &&
                <Tab eventKey="financeirogeral" title="Financeiro Geral" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    <DashFinanceiroGeral ref={refFinanceiroGeral} />
                </Tab>
            }
            {auth.useAppRequisicoes &&
                <Tab eventKey="requisicoes" title="Requisições" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    <DashRequisicoes ref={refRequisicoes} />
                </Tab>
            }
            {auth.useAppDP &&
                <Tab eventKey="colaboradores" title="Colaboradores" style={{ backgroundColor: 'var(--bg-color-secondary)' }}>
                    <DashColaboradores ref={refColaboradores} />
                </Tab>
            }
        </Tabs>
    );
}
