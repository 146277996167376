import React from 'react';
import styled from 'styled-components';
import { getIconCultura } from '../../commons/utils';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
    margin: 15px 8px 0px;
`;

const ExButton = styled.button<{ color: string }>`
    background-color: ${({ color }) => color};
    border: none;
    padding: 3px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
`;

const Icon = styled.img`
   margin-right: 6px;
   pointer-events: none;
`

interface FiltroCulturaProps {
    data: any[];
    culturaChecked: { id: number; descricao: string };
    onChecked: any;
}

const FiltroCultura: React.FC<FiltroCulturaProps> = ({ data, culturaChecked, onChecked }) => {
    const cultura_options = new Set<string>();

    return (
        <Container>
            {data?.map((_data) => {
                if (!cultura_options.has(_data.cultdescricao)) {
                    cultura_options.add(_data.cultdescricao);

                    return (
                        <ButtonWrapper key={_data.id}>
                            <ExButton
                                color={culturaChecked.descricao === _data.cultdescricao ? 'var(--primary-color)' : '#ffb87a36'}
                                onClick={() => onChecked({ id: _data.cultid, descricao: _data.cultdescricao })}
                            >
                                <Icon src={getIconCultura(_data.cultdescricao)} width={32} alt='icone cultura' />
                                {_data.cultdescricao}
                            </ExButton>
                        </ButtonWrapper>
                    );
                }
                return null;
            })}
        </Container>
    );
};

export default FiltroCultura;
