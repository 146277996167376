import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { DataTable } from '../DataTable';
import { Column } from 'primereact/column';
import { Card } from 'react-bootstrap';
import { formatDateTimeFile, getLocalStorage, isMobileDevice } from '../../commons/utils';
import { CardTable, Header, Title, Icon } from './components';
import { ExSwitch } from '../ExSwitch';
import { ExButton } from '../ExButton';
import { ExCadastroPadrao } from '../ExCadastroPadrao';
import { toast } from 'react-toastify';
import { confirm } from '../Confirmation';
import { ExTooltip } from '../Tooltip';
import { Calendar } from 'primereact/calendar';
import { FilterOperator, FilterMatchMode } from 'primereact/api';
import { ColumnConfigurator } from '../ColumnConfigurator';
import exportJsonToExcel from '../JsonToExcel';
import { FileUp, Pencil, Plus, Settings } from 'lucide-react';
import { Privilegio } from '../../contexts/AuthProvider/types';

export interface ConsultaPadraoFunction {
    label?: string
    icon: React.ReactNode
    color?: string
    click: () => void
    disabled?: boolean
}
interface ConsultaPadraoProps {
    ref?: any;
    model: any;
    columns: any;
    sortField?: any;
    details?: any;
    functions?: (row: any) => ConsultaPadraoFunction[];
    apifilter?: any;
    disableedit?: any;
    headerfuncions?: any;
    inputfilters?: any;
    emptyopen?: any;
    groupcolumn?: any;
    groupfooter?: any;
    groupheader?: any;
    changefilter?: any;
    disabledrefresh?: any;
    sortOrder?: any;
    onAfterRefresh?: any;
}

export const ConsultaPadrao: React.FC<ConsultaPadraoProps> = forwardRef((props, ref) => {
    const auth = useAuth();

    const refDataTable = useRef<any>();
    const refCadastroPadrao = useRef<any>();
    const refColumnConfig = useRef<any>();

    const [data, setData] = useState<any>([]);
    const [originValues, setOriginValues] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters, setFilters] = useState(undefined);
    const [cols, setCols] = useState(convertBodyColumns(getLocalStorage('exconfigtable_' + auth.usucodigo + '_' + props.model?.name)) ?? props.columns)

    const initFilters = () => {
        const _filters: any = {};

        props.columns.forEach((column: any) => {
            let matchMode = FilterMatchMode.CONTAINS;
            if (column.dataType === 'numeric' || column.dataType === 'date') {
                matchMode = FilterMatchMode.EQUALS;
            }
            _filters[column.field] = {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: matchMode }]
            };
        });

        setFilters(_filters);
    };

    function setCustomData(_data: any) {
        initFilters();
        refDataTable.current.setData(_data);
        setData(_data);
    }

    function getData() {
        return data
    }

    function setColumnsInvisibles(_cols: { field: string; invisible: boolean }[]) {
        setCols((prevCols: any[]) =>
            prevCols.map((col) => {
                const updatedCol = _cols.find((c) => c.field === col.field);
                return {
                    ...col,
                    invisible: updatedCol ? updatedCol.invisible : col.invisible
                };
            })
        );
    }


    useImperativeHandle(ref, () => ({
        refresh,
        getData,
        setCustomData,
        setColumnsInvisibles
    }));

    useEffect(() => {
        refresh();
        initFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCustomers = (data: any) => {
        const dateColumns = props.columns.filter((column: any) => column.dataType === 'date').map((column: any) => column.field);

        return [...(data || [])].map((d) => {
            dateColumns.forEach((field: string) => {
                if (d[field]) {
                    d[field] = new Date(d[field]);
                }
            });

            return d;
        });
    };

    function refresh(_filter?: any) {
        setLoading(true);

        auth.get(props.model.api_get + (_filter ? _filter : props.apifilter ? props.apifilter : '')).then((response: any) => {
            if (process.env.NODE_ENV === 'development')
                console.log('Consulta ' + props.model.name, response.data);

            let _data = getCustomers(response.data);

            if (!props.emptyopen) {
                if (props.onAfterRefresh)
                    props.onAfterRefresh(_data)
                else {
                    refDataTable.current.setData(_data);
                    setData(_data);
                }
            }

            setOriginValues(_data);
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    async function ativaInativa(row: any, ativo: boolean) {
        if ((ativo) || (await confirm('Deseja mesmo inativar este registro?'))) {
            let body: any = { ...row };

            delete body.ususenha;

            body[props.model.inactive_field] = ativo ? 1 : 0;

            auth.post(props.model.api_post, body).then(() => {
                toast.success('Registro ' + (ativo ? 'ativado' : 'inativado') + ' com sucesso.');
                refresh();
            }).catch((error: any) => {
                toast.error(error.response.data.error ? error.response.data.error : 'Erro ao concluir a operação.');
                console.log(error)
            })
        }
    }

    function tableAtivoInativo(row: any) {
        return (
            <ExSwitch checked={row[props.model.inactive_field] === 1} onChange={(e: any) => ativaInativa(row, e.target.checked)} />
        )
    }

    function tableFunctions(row: any) {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.functions &&
                    props.functions(row)?.map((item: any, i: number) => (
                        <ExTooltip key={i} title={item.label}>
                            <ExButton size='sm' color={item.color} className='m-0 me-2 p-1' style={{ borderRadius: '6px' }} onClick={() => item.click(row)} iconcenter={item.icon} disabled={item.disabled} />
                        </ExTooltip>
                    ))}

                {props.model.base && !props.disableedit && auth.privilegio !== Privilegio.Normal &&
                    <ExButton size='sm' color={'var(--primary-color)'} className='m-0 me-2 p-1' style={{ borderRadius: '6px' }} onClick={() => refCadastroPadrao.current.openModal(row)} iconcenter={<Pencil size={20} />} />
                }
            </div>
        )
    }

    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    function convertBodyColumns(columns: any) {
        const prospColumnsMap = props.columns.reduce((map: any, col: any) => {
            if (col.field) map[col.field] = { body: col.body, invisible: col.invisible };

            return map;
        }, {} as Record<string, any>);

        return columns?.map((column: any) => ({
            ...column,
            body: prospColumnsMap[column.field]?.body || column.body,
            invisible: prospColumnsMap[column.field]?.invisible ?? column.invisible
        }));
    }

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-3 mb-4 mt-3" : "m-4 mb-4"}>
                <Card.Body className={isMobileDevice() ? "p-1" : "p-2"}>
                    <Header style={{ display: isMobileDevice() ? 'block' : 'flex' }}>
                        <Title>
                            <Icon>{props.model?.icon}</Icon>
                            {props.model?.name}
                        </Title>
                        <div style={{ display: 'flex', marginTop: isMobileDevice() ? '10px' : 0 }}>
                            {props.headerfuncions &&
                                props.headerfuncions.map((func: any, i: number) => (
                                    <ExButton secondary key={i} icon={func.icon} onClick={() => func.onClick(refDataTable.current.getData())} style={{ marginRight: '8px' }} >{func.title}</ExButton>
                                ))
                            }
                            <ExButton secondary icon={!isMobileDevice() && <FileUp />} style={{ marginRight: '8px' }} onClick={() => { exportJsonToExcel(props.model.name + ' ' + formatDateTimeFile(new Date()), data, props.model) }}>Exportar</ExButton>
                            <ExButton secondary icon={!isMobileDevice() && <Settings />} style={{ marginRight: '8px' }} onClick={() => refColumnConfig.current.openModal()}>Configurar Grade</ExButton>
                            {props.model.base && auth.privilegio !== Privilegio.Normal &&
                                <ExButton icon={!isMobileDevice() && <Plus />} onClick={() => refCadastroPadrao.current.openModal()}>Novo</ExButton>
                            }
                        </div>
                    </Header>
                    <DataTable
                        ref={refDataTable}
                        model={props.model}
                        value={data}
                        loading={loading}
                        refresh={refresh}
                        sortField={props.sortField}
                        sortOrder={props.sortOrder}
                        rowExpansionTemplate={props.details}
                        expandedRows={expandedRows}
                        onRowToggle={(e: any) => setExpandedRows(e.data)}
                        originvalues={originValues}
                        inputfilters={props.inputfilters}
                        rowGroupMode={props.groupcolumn ? "subheader" : undefined}
                        groupRowsBy={props.groupcolumn}
                        rowGroupFooterTemplate={props.groupfooter}
                        rowGroupHeaderTemplate={props.groupheader}
                        changefilter={props.changefilter}
                        filters={filters}
                        disabledrefresh={props.disabledrefresh}
                    >
                        {props.details && <Column expander={true} style={{ fontSize: '12px', width: '2.5rem', marginBottom: '-10px' }} />}
                        {
                            cols
                                .filter((col: any) => (!col.invisibleconf && !col.invisible))
                                .map((col: any, i: number) => (
                                    <Column
                                        key={col.field}
                                        filter={(col.header) && (col.field !== 'functions') && (col.header !== 'Ativo')}
                                        sortable={(col.notSortable || col.field === 'functions' || col.header === 'Ativo') ? false : true}
                                        field={col.field}
                                        dataType={col.dataType}
                                        align={isMobileDevice() || (col.dataType === 'numeric') ? 'right' : 'left'}
                                        header={col.header}
                                        body={col.field === 'functions' ? (e) => tableFunctions(e) : col.header === 'Ativo' ? (e) => tableAtivoInativo(e) : col.body}
                                        style={{ ...col.style, fontSize: '12px', marginBottom: `${(i + 1) === cols.length ? '0px' : '-14px'}` }}
                                        filterElement={col.dataType === 'date' && dateFilterTemplate}
                                        showFilterOperator={false}
                                    />
                                ))
                        }
                    </DataTable>
                </Card.Body>
            </CardTable>
            <ColumnConfigurator ref={refColumnConfig} module={props.model?.name} columns={cols} setColumns={setCols} />
            {props.model.base &&
                <ExCadastroPadrao ref={refCadastroPadrao} model={props.model} success={() => refresh()} />
            }
        </>
    )
})