import styled from "styled-components";

export const ButtonFooter = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    font-size: 24px;
    padding: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: var(--icon-color);

    &:hover {
        background-color: var(--hover-icon-color);
    }

    &:active {
        background-color: transparent;
    }
`