import { forwardRef, memo, useCallback, useDeferredValue, useEffect, useImperativeHandle, useState } from "react";
import ColumnContainer, { ColumnContainerProps } from "./ColumnContainer";
import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { Input } from "../Input";
import { isMobileDevice } from "../../commons/utils";

export type Id = string | number;

export type Column = {
    id: Id;
    title: string;
    color: any
    icon: any;
    value: number
};

export type Task = {
    id: Id;
    columnId: Id;
    content: string;
    details?: any;
    infoAdditional?: any;
    functions?: any;
    filterValues?: string[]
};

const Container = styled.div`
    display: flex; 
    height: auto;
    width: 100%;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 20px; 

    &::-webkit-scrollbar {
        height: 9px; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(172, 172, 172); 
        border-radius: 6px; 
    }
`;

const Wrapper = styled.div`
    margin: auto;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px; 
    }
`;

const WrapperChild = styled.div`
    display: flex;
    gap: 15px;
`;

const InternalColumnContainer = memo(({ column, deleteTask, updateTask, tasks, searchText }: ColumnContainerProps & { searchText: string }) => {

    const searchTextProc = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const searchWords = searchTextProc.split(' ')

    const filteredTasks = tasks.filter(item => {
        if (searchTextProc.length === 0) return true

        return item.filterValues?.some((field) => {
            const fieldProc = String(field).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            return searchWords.every(w => fieldProc.includes(w))
        })
    })

    return (
        <ColumnContainer
            column={column}
            deleteTask={deleteTask}
            updateTask={updateTask}
            tasks={filteredTasks}
        />
    )
})

const KanbanBoard = forwardRef((props: any, ref: any) => {
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const globalFilterDeferred = useDeferredValue(globalFilter)
    const [tasks, setTasks] = useState<Task[]>([]);
    const [toolbarWidth, setToolbarWidth] = useState<string>('100%');

    useImperativeHandle(ref, () => ({
        setTasks
    }));

    const [activeTask, setActiveTask] = useState<Task | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    useEffect(() => {
        const toolbarElement = document.getElementById("principal-toolbar");

        if (toolbarElement) {
            const observer = new ResizeObserver(() => {
                setToolbarWidth('calc(' + toolbarElement.offsetWidth + 'px - 4px)');
            });

            observer.observe(toolbarElement);

            return () => observer.unobserve(toolbarElement);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteTask = useCallback((id: Id) => {
        const newTasks = tasks.filter((task) => task.id !== id);
        setTasks(newTasks);
    }, [tasks])

    const updateTask = useCallback((id: Id, content: string) => {
        const newTasks = tasks.map((task) => {
            if (task.id !== id) return task;
            return { ...task, content };
        });

        setTasks(newTasks);
    }, [tasks])

    function onDragStart(event: DragStartEvent) {
        if (event.active.data.current?.type === "Column") {
            return;
        }

        if (event.active.data.current?.type === "Task") {
            setActiveTask(event.active.data.current.task);
            return;
        }
    }

    function onDragEnd(event: DragEndEvent) {
        setActiveTask(null);

        const { active } = event;

        if (!active) return;

        const item: any = props.status?.find((item: any) => item.id === active.data.current?.task.columnId);
        props.updateStatus(active.id, item.value);
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveATask = active.data.current?.type === "Task";
        const isOverATask = over.data.current?.type === "Task";

        if (!isActiveATask) return;

        if (isActiveATask && isOverATask) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                const overIndex = tasks.findIndex((t) => t.id === overId);

                if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
                    tasks[activeIndex].columnId = tasks[overIndex].columnId;
                    return arrayMove(tasks, activeIndex, overIndex - 1);
                }

                return arrayMove(tasks, activeIndex, overIndex);
            });
        }

        const isOverAColumn = over.data.current?.type === "Column";

        if (isActiveATask && isOverAColumn) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                tasks[activeIndex].columnId = overId;
                return arrayMove(tasks, activeIndex, activeIndex);
            });
        }
    }

    return (
        <div style={{ maxWidth: toolbarWidth }}>
            <Grid container spacing={3} style={{ padding: isMobileDevice() ? '8px 20px' : '4px 20px' }}>
                {props.filter && props.filter()}
                <Grid item xs={12}>
                    <Input id="search" type="text" placeholder="Pesquisar" noDebounce search onAfterChange={(value: any) => setGlobalFilter(value)} />
                </Grid>
            </Grid>
            <Container>
                <DndContext
                    sensors={sensors}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                >
                    <Wrapper>
                        <WrapperChild >
                            <SortableContext items={props.columnsId}>
                                {props.status.map((col: any) => (
                                    <InternalColumnContainer
                                        key={col.id}
                                        column={col}
                                        deleteTask={deleteTask}
                                        updateTask={updateTask}
                                        tasks={tasks.filter((task) => task.columnId === col.id)}
                                        searchText={globalFilterDeferred}
                                    />
                                ))}
                            </SortableContext>
                        </WrapperChild>
                    </Wrapper>

                    {createPortal(
                        <DragOverlay>
                            {activeTask && (
                                <TaskCard
                                    task={activeTask}
                                    color={'#fff'}
                                    deleteTask={deleteTask}
                                    updateTask={updateTask}
                                />
                            )}
                        </DragOverlay>,
                        document.body
                    )}

                </DndContext>
            </Container>
        </div>
    );
})

export default KanbanBoard;