import 'primeicons/primeicons.css';
import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
import 'primereact/resources/primereact.css';
import './styles.css';
import React, { useState, useMemo, forwardRef, useImperativeHandle, useDeferredValue, memo } from 'react';
import { DataTable as DataTablePrimeReact, DataTableProps } from 'primereact/datatable';
import { Input } from '../Input';
import styled from 'styled-components';
import { Grid, Tooltip } from '@mui/material';
import { ButtonFooter } from '../ExButtonDataTable';
import { locale, addLocale } from 'primereact/api';
import { ExControls } from '../ExControls';
import { ExButton } from '../ExButton';
import { isMobileDevice } from '../../commons/utils';
import { RotateCcw } from 'lucide-react';

// Adiciona a configuração do locale para o português
addLocale('pt', {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sem filtro',
    lt: 'Menor que',
    lte: 'Menor ou igual a',
    gt: 'Maior que',
    gte: 'Maior ou igual a',
    dateIs: 'Data igual a',
    dateIsNot: 'Data diferente de',
    dateBefore: 'Data antes de',
    dateAfter: 'Data depois de',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Corresponder a todos',
    matchAny: 'Corresponder a qualquer',
    addRule: 'Adicionar Condição',
    removeRule: 'Remover Condição',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Upload',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    weekHeader: 'Sm',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyMessage: 'Nenhum resultado encontrado',
    emptyFilterMessage: 'Nenhum resultado encontrado'
});

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 10px;
`

const TextHeader = styled.div`
    font-size: 12px;
    margin-right: 7px;
`

const ButtonHeader = styled(ExButton)`
    margin: 0px 4px;
    height: 25px;
    font-size: 12px;
`

interface ExDataTableProps extends DataTableProps {
    refresh?: () => void;
    inputfilters?: any;
    originvalues: any;
    changefilter?: any;
    disabledrefresh?: any;
    maxRows?: any;
    model?: any;
}

const InternalDataTable = memo((props: ExDataTableProps & { setData: (data: any[]) => void, data: any[], searchText: string, ativo: boolean, inativo: boolean }) => {
    const { refresh, inputfilters, originvalues, changefilter, disabledrefresh, setData, data, searchText, ativo, inativo, ...dataTableProps } = props;

    const MAX_ROWS = props.maxRows ?? 50;

    const onValueChange = (e: any) => {
        setCustomData(e);
    };

    function setCustomData(_data: any) {
        if (props.changefilter)
            props.changefilter(_data);

        setData(_data);
    }

    const filteredData = useMemo(() => {
        let _data: any = [];

        if (!props.value)
            return _data;

        let _values: any = props.value;

        if (props.model?.inactive_field) {
            if (!ativo && !inativo)
                _values = [];
            else {
                _values = _values.filter((e: any) => {
                    const isActive = e[props.model.inactive_field] === 1;
                    return ativo && inativo ? true : ativo ? isActive : !isActive;
                });
            }
        }

        const searchTextProc = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        const searchWords = searchTextProc.split(' ')

        _data = _values.filter((item: any) =>
            Object.values(item).some((field) => {
                const fieldProc = String(field).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                return searchWords.every(w => fieldProc.includes(w))
            })
        );

        return _data;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, searchText, ativo, inativo]);

    return (
        <DataTablePrimeReact
            {...dataTableProps}
            className="m-0"
            paginatorRight={
                !props.disabledrefresh ?
                    <Tooltip title="Recarregar">
                        <ButtonFooter onClick={() => { props.refresh && props.refresh() }}>
                            <RotateCcw />
                        </ButtonFooter>
                    </Tooltip> :
                    <></>
            }
            paginatorLeft={<></>}
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={MAX_ROWS}
            paginatorTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                    : "CurrentPageReport"
            }
            currentPageReportTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "{first} à {last} de {totalRecords}"
                    : "{totalRecords} Registros"
            }
            expandedRowIcon="pi pi-angle-down"
            collapsedRowIcon="pi pi-angle-right"
            paginator={true}
            scrollHeight={'100%'}
            sortField={props.sortField}
            sortOrder={props.sortOrder ? props.sortOrder : 1}
            value={filteredData}
            onValueChange={onValueChange}
        />
    )
})

export const DataTable = forwardRef((props: ExDataTableProps, ref) => {
    locale('pt');

    const [data, setData] = useState<any[]>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const globalFilterDeferred = useDeferredValue(globalFilter)
    const [ativo, setAtivo] = useState<any>(true);
    const [inativo, setInativo] = useState<any>(false);

    const { refresh, inputfilters, originvalues, changefilter, disabledrefresh, ...dataTableProps } = props;

    function getData() {
        return data
    }

    useImperativeHandle(ref, () => ({
        getData,
        setData
    }));

    return (
        <>
            <Header>
                <Grid container spacing={2}>
                    {props.inputfilters &&
                        props.inputfilters.map((input: any, i: number) => (
                            <Grid key={i} item xs={isMobileDevice() ? 12 : input.md}>
                                <ExControls
                                    ref={input.ref}
                                    id={input.id}
                                    name={input.id}
                                    type={input.type}
                                    label={input.label}
                                    required={input.required}
                                    model={input.model}
                                    isMulti={input.isMulti}
                                    defaultValue={input.defaultValue}
                                    onAfterChange={(_newValue: any) => input.onChange?.(_newValue, props.originvalues)}
                                    onFilter={input.onFilter}
                                    customStyle={input.customStyle}
                                    onClick={input.onClick}
                                />
                            </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                        <Input id="search" type="text" placeholder="Pesquisar" size="md" noDebounce search onAfterChange={(value: any) => setGlobalFilter(value)} />
                    </Grid>
                    {props.model?.inactive_field &&
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', paddingTop: '12px' }}>
                            <TextHeader>Mostrar:</TextHeader>
                            <ButtonHeader color={ativo ? 'var(--primary-color)' : '#747474'} onClick={() => setAtivo(!ativo)} >Ativos</ButtonHeader>
                            <ButtonHeader color={inativo ? 'var(--primary-color)' : '#747474'} onClick={() => setInativo(!inativo)} >Inativos</ButtonHeader>
                        </Grid>
                    }
                </Grid>
            </Header>
            {/* troca globalFilterDeferred por globalFilter abaixo pra ver a diferença */}
            <InternalDataTable {...props} setData={setData} data={data} searchText={globalFilterDeferred} ativo={ativo} inativo={inativo} />
        </>
    );
});
