import React, { useState } from "react";
import { Divider, IconButton, List, ListItemButton, Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { Link } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import styled from "styled-components";
import moment from "moment";
import { DRAWER_WIDTH, ExListItemIcon, ExListItemText, ExToolBarLogo } from "./components";
import { isMobileDevice } from "../../../commons/utils";
import { Building2, ChevronDown, ChevronRight, LayoutDashboard, ListCollapse, LogOut, User, X } from "lucide-react";
import IconAppEstoques from '../../../images/Estoques.png';
import IconAppDP from '../../../images/DepartamentoPessoal.png';
import IconAppRequisicoes from '../../../images/Requisições.png';
import { IUser, Privilegio } from "../../../contexts/AuthProvider/types";

const rotas = [
    { label: 'Dashboard', to: '/', icon: <LayoutDashboard />, privilegio: (auth: IUser) => (auth.useAppRequisicoes || auth.useAppEstoques || auth.useAppDP) },
    { label: 'Usuários', to: '/usuario', icon: <User />, privilegio: (auth: IUser) => (auth.privilegio !== Privilegio.Normal) },
    {
        label: 'Grupos', icon: <Building2 />, privilegio: (auth: IUser) => (auth.privilegio !== Privilegio.Normal),
        children: [
            { label: 'Grupo', to: '/grupo' },
            { label: 'Empresa', to: '/grupoempresa' }
        ],
    },
    {
        label: 'Estoques', icon: <img src={IconAppEstoques} alt='Icon App Estoques' height={20} width={20} />,
        children: [
            { label: 'Cultura', to: '/cultura', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Unidade Medida', to: '/produtounidademedida', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Produto', to: '/produto', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Unidade Conversão', to: '/produtounidadeconversao', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Medidas de Produto', to: '/produtomedida', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Valores Complementares', to: '/valorescomplementares', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Campos', to: '/movimentoestoquecampos', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Tipo de Movimento', to: '/tipomovimento', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Tipo de Movimentações de Produtos', to: '/tipomovimento-produto', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Status', to: '/tipomovimento-status', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Movimentos', to: '/estoques-movimento' },
            { label: 'Movimentos Por Período', to: '/relatorio/movimentosporperiodo' },
        ]
    },
    {
        label: 'Requisições', icon: <img src={IconAppRequisicoes} alt='Icon App Requisições' height={20} width={20} />,
        children: [
            { label: 'Campos', to: '/camposrequisicao', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Status', to: '/status', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Lançamentos', to: '/requisicoes-kanban' }
        ]
    },
    {
        label: 'Departamento Pessoal', icon: <img src={IconAppDP} alt='Icon App Departamento Pessoal' height={20} width={20} />,
        children: [
            { label: 'Status', to: '/colaboradorstatus', privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador) },
            { label: 'Colaboradores (Lista)', to: '/colaboradores' },
            { label: 'Colaboradores (Kanban)', to: '/colaboradores-kanban' }
        ],
    },
    {
        label: 'Outros', icon: <ListCollapse />, privilegio: (auth: IUser) => (auth.privilegio === Privilegio.Admnistrador),
        children: [
            { label: 'Campos', to: '/campos' }
        ],
    },
];


const ExDivider = () => {
    return (
        <Divider variant='middle' style={{ backgroundColor: '#f1f1f1' }} />
    )
}

const ButtonRetrair = (props: any) => {
    return (
        <>
            {
                isMobileDevice() &&
                <IconButton color="inherit" edge="end" sx={{ ml: 8 }} onClick={() => props?.onClick && props.onClick()}>
                    <X />
                </IconButton>
            }
        </>
    )
}

const ExFooterCopyright = styled.footer`
    bottom: 0;
    width: ${DRAWER_WIDTH};
    background-color: 'var(--side-bar-color)';
    color: #fff;
    text-align: center;
    margin: 20px 0 20px 0;
`

interface OpenState {
    [key: string]: boolean;
}

export const SideBar = (props: any) => {
    const auth = useAuth();

    const [openSubItem, setOpenSubItem] = useState<OpenState>({ Cadastros: true });

    const handleClick = (label: any, to: any) => {
        props?.clickItemMenu && props.clickItemMenu(to);
        setOpenSubItem({ ...openSubItem, [label]: !openSubItem[label] });
    };

    return (
        <>
            <ExToolBarLogo> <ButtonRetrair onClick={props.clickExpanded} /> </ExToolBarLogo>
            <div style={{ paddingLeft: '14px', paddingTop: '14px', fontSize: '14px' }}>
                {'Usuário: ' + auth.usunome} <br />
            </div >
            <List>
                {rotas.map((rota: any, index: number) => (
                    ((!rota.privilegio) || rota.privilegio(auth)) &&
                    <React.Fragment key={index}>
                        <ListItemButton component={rota.to && Link} to={rota.to} onClick={() => handleClick(rota.label, rota.to)} >
                            <ExListItemIcon icon={rota.icon} />
                            <ExListItemText label={rota.label} />
                            {rota.children ?
                                openSubItem[rota.label] ?
                                    <ChevronDown width={'18px'} /> : <ChevronRight width={'18px'} /> : <></>
                            }
                        </ListItemButton>

                        {
                            rota.children?.map((child: any, i: number) => (
                                <Collapse key={i} in={openSubItem[rota.label]} timeout={300} unmountOnExit >
                                    {
                                        ((!child.privilegio) || child.privilegio(auth)) &&
                                        <ListItemButton component={Link} to={child?.to} onClick={() => { props?.clickItemMenu && props.clickItemMenu('') }} dense>
                                            <ExListItemText label={child.label} sx={{ ml: 4 }} />
                                        </ListItemButton>
                                    }
                                </Collapse>
                            ))
                        }
                        {(index !== rotas.length - 1) && <ExDivider />}
                    </React.Fragment>
                ))}

                <ExDivider />
                <ListItemButton onClick={() => auth.logout()}>
                    <ExListItemIcon icon={<LogOut />} />
                    <ExListItemText label='Sair' />
                </ListItemButton>
            </List>


            <Divider style={{ backgroundColor: '#f1f1f1ba' }} />

            <ExFooterCopyright>
                <Typography sx={{ fontSize: 11, mt: '10px' }}>
                    {'© Copyright Exalt ' + moment().year() + '.'} <br />
                    {'Todos os direitos reservados.'}
                </Typography>
            </ExFooterCopyright>
        </>
    )
}