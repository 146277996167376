import { forwardRef, useImperativeHandle, useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import "./styles.css";
import styled from "styled-components";

export const FormLabelCheckBox = styled(Form.Label)`
    display: 'flex';
    margin: 0; 
    font-size: 12px;
    font-weight: 500;
    margin-left: 6px !important;
    margin-top: 3px;
    padding: 1px;
`

interface ExCheckBoxProps {
    id?: any;
    defaultValue?: any;
    label?: String;
    md?: any;
    size?: any;
    type?: any;
    group?: any;
    onClick?: any;
    required?: any;
}

export const ExCheckBox = forwardRef((props: ExCheckBoxProps, ref: any) => {
    const [value, setValue] = useState<any>(props.defaultValue === 1);

    function getValue() {
        return value ? 1 : 0
    }

    function getId() {
        return props.id
    }

    function validate() {
        return true
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        getId,
        validate
    }));

    function onClick(e: any) {
        setValue(!value);

        if (props.onClick)
            props.onClick(e)
    }

    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"} style={{ marginBottom: '2px' }}>
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                <Form.Check
                    inline
                    name={props.group}
                    type={props.type ? props.type : "checkbox"}
                    defaultChecked={props.defaultValue === 1}
                    onClick={(e: any) => onClick(e)}
                    required={props.required || props.type === 'radio'}
                    style={{ fontSize: '0.950rem', display: 'flex', alignItems: 'center', margin: 0, marginBottom: '1px' }}
                />
                {props.label && <FormLabelCheckBox>{props.label}</FormLabelCheckBox>}
            </InputGroup>
        </Form.Group>
    )
});