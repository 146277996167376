import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatDecimal, isMobileDevice } from "../../../commons/utils";
import BarChart from "../../../charts/Bar";
import { Columns, GridCard } from "..";
import { ExCard } from "../../../charts/Card";
import { DataTable } from "../../../components/DataTable";
import { ExPaper } from "../../../components/Paper";
import { Boxes, MapPinned, Weight } from "lucide-react";

export const DashEntradas = forwardRef((props: any, ref) => {
    const [originData, setOriginData] = useState<any[]>([]);
    const [movEntrada, setMovEntrada] = useState<any>([]);

    const refMovimento = useRef<any>();
    const refTotalEmbalagem = useRef<any>();
    const refTotalHa = useRef<any>();
    const refTotalPesagem = useRef<any>();
    const refPesoMedio = useRef<any>();
    const refPesagemMedia = useRef<any>();

    useEffect(() => {
        populateChart(originData, props?.cultura?.descricao);
        populateGrid(originData, props?.cultura?.descricao);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cultura]);

    useImperativeHandle(ref, () => ({
        setData
    }));

    async function sumTotaisEntrada(_data: any) {
        const totalEmbalagem = _data.reduce((acc: number, item: any) => acc + (item.totalembalagem || 0), 0);
        const totalPesagem = _data.reduce((acc: number, item: any) => acc + (item.totalpesagem || 0), 0);
        const totalArea = _data.reduce((acc: number, item: any) => acc + (item.plarea || 0), 0);
        const totalPesoMedio = _data.reduce((acc: number, item: any) => acc + (item.pesomedio || 0), 0);

        refTotalEmbalagem.current.setValue(formatDecimal(totalEmbalagem));
        refTotalHa.current.setValue(formatDecimal(totalArea));
        refTotalPesagem.current.setValue(formatDecimal(totalPesagem));
        refPesoMedio.current.setValue(formatDecimal(totalPesoMedio / _data.length));
        refPesagemMedia.current.setValue(formatDecimal(totalArea === 0 ? 0 : totalPesagem / totalArea))
    }

    function populateChart(_data: any, cultura?: any) {
        if (cultura)
            _data = _data.filter((item: any) => item.cultdescricao === cultura);

        const valores = _data.map((item: any) => parseFloat((item.pesagemarea || 0).toFixed(2)));
        const descricoes = _data.map((item: any) => item?.pldescricao);

        const getNextMultipleOf5000 = (value: number) => { return Math.ceil(value / 5000) * 5000 };

        refMovimento?.current.setOptions(descricoes, getNextMultipleOf5000(Math.max(Math.max(...valores), 0)), 'Pesagem por Ha');
        refMovimento?.current.setSeries([{ name: 'Pesagem por Ha', data: valores }]);

        sumTotaisEntrada(_data);
    }

    function populateGrid(_data: any, cultura?: any) {
        if (cultura)
            _data = _data.filter((item: any) => item.cultdescricao === cultura);

        setMovEntrada(_data);
    }

    function setData(_data: any) {
        setOriginData(_data);

        if (_data && _data.length > 0)
            props.onChangeCultura({ id: _data[0].cultid, descricao: _data[0].cultdescricao });
        else {
            populateChart(_data);
            populateGrid(_data);
        }
    }

    const columns = [
        { field: 'pldescricao', header: 'Plantio' },
        { field: 'totalpesagem', header: 'Total Pesagem', body: (e: any) => formatDecimal(e.totalpesagem), dataType: 'numeric' },
        { field: 'totalembalagem', header: 'Total Embalagens', body: (e: any) => formatDecimal(e.totalembalagem), dataType: 'numeric' },
        { field: 'plarea', header: 'Total Ha', body: (e: any) => formatDecimal(e.plarea), dataType: 'numeric' },
        { field: 'pesomedio', header: 'Peso Médio', body: (e: any) => formatDecimal(e.pesomedio), dataType: 'numeric' },
        { field: 'pesagemarea', header: 'Pesagem por Ha', body: (e: any) => formatDecimal(e.pesagemarea), dataType: 'numeric' },
        { field: 'embalagemarea', header: 'Embalagem por Ha', body: (e: any) => formatDecimal(e.embalagemarea), dataType: 'numeric' },
    ];

    return (
        <Grid container spacing={4} sx={{ padding: isMobileDevice() ? '1rem' : '1rem 4rem' }}>
            <Grid item xs={12}>
                <BarChart ref={refMovimento} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <GridCard md={2.4}>
                        <ExCard ref={refTotalPesagem} title='Total Pesagem' icon={<Weight />} />
                    </GridCard>
                    <GridCard md={2.4}>
                        <ExCard ref={refTotalEmbalagem} title='Total de Embalagens' icon={<Boxes />} />
                    </GridCard>
                    <GridCard md={2.4}>
                        <ExCard ref={refTotalHa} title='Total Ha' icon={<MapPinned />} />
                    </GridCard>
                    <GridCard md={2.4}>
                        <ExCard ref={refPesagemMedia} title='Pesagem Ha' icon={<Weight />} />
                    </GridCard>
                    <GridCard md={2.4}>
                        <ExCard ref={refPesoMedio} title='Peso Medio' icon={<Weight />} />
                    </GridCard>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ExPaper>
                    <DataTable value={movEntrada} originvalues={movEntrada} disabledrefresh={true}>
                        {Columns(columns)}
                    </DataTable>
                </ExPaper>
            </Grid>
        </Grid>
    )
})