import { Route, Routes } from "react-router-dom"
import { RequireAuth, RequireAuthAdm, RequireAuthDP, RequireAuthEstoques, RequireAuthGestao, RequireAuthRequisicoes } from "../contexts/AuthProvider/RequireAuth"
import { Usuario } from "../pages/Usuario"
import { Status } from "../pages/Requisicoes/Status"
import { RequisicaoCampos } from "../pages/Requisicoes/RequisicaoCampos"
import { Grupo } from "../pages/Grupos/Grupo"
import { TipoMovimento } from "../pages/Estoques/TipoMovimento"
import { Produto } from "../pages/Estoques/Produto"
import { Cultura } from "../pages/Estoques/Cultura"
import { ProdutoUnidadeMedida } from "../pages/Estoques/ProdutoUnidadeMedida"
import { ProdutoUnidadeConversao } from "../pages/Estoques/ProdutoUnidadeConversao"
import { MovimentoEstoqueCampos } from "../pages/Estoques/MovimentoEstoqueCampos"
import { MovimentosPorPeriodo } from "../pages/Estoques/MovimentosPorPeriodo"
import { Dashboard } from "../pages/Dashboard"
import { GrupoEmpresa } from "../pages/Grupos/GrupoEmpresa"
import { Campos } from "../pages/Estoques/Campos"
import { ValoresComplementares } from "../pages/Estoques/ValoresComplementares"
import { RequisicoesKanban } from "../pages/Requisicoes/RequisicoesKanban"
import { ColaboradorStatus } from "../pages/DepartamentoPessoal/ColaboradorStatus"
import { ProdutoMedida } from "../pages/Estoques/ProdutoMedida"
import { TipoMovimentoStatus } from "../pages/Estoques/TipoMovimentoStatus"
import { Colaborador } from "../pages/DepartamentoPessoal/Colaborador"
import { ColaboradorKanban } from "../pages/DepartamentoPessoal/ColaboradorKanban"
import { TipoMovimentoProduto } from "../pages/Estoques/TipoMovimentoProduto"
import { EstoquesMovimentos } from "../pages/Estoques/Movimentos"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/usuario" element={<RequireAuthGestao><Usuario /></RequireAuthGestao>} />
            <Route path="/status" element={<RequireAuthRequisicoes><Status /></RequireAuthRequisicoes>} />
            <Route path="/camposrequisicao" element={<RequireAuthRequisicoes><RequisicaoCampos /></RequireAuthRequisicoes>} />
            <Route path="/grupo" element={<RequireAuthGestao><Grupo /></RequireAuthGestao>} />
            <Route path="/grupoempresa" element={<RequireAuthGestao><GrupoEmpresa /></RequireAuthGestao>} />
            <Route path="/tipomovimento" element={<RequireAuthAdm><TipoMovimento /></RequireAuthAdm>} />
            <Route path="/produto" element={<RequireAuthAdm><Produto /></RequireAuthAdm>} />
            <Route path="/cultura" element={<RequireAuthAdm><Cultura /></RequireAuthAdm>} />
            <Route path="/produtounidademedida" element={<RequireAuthAdm><ProdutoUnidadeMedida /></RequireAuthAdm>} />
            <Route path="/produtounidadeconversao" element={<RequireAuthAdm><ProdutoUnidadeConversao /></RequireAuthAdm>} />
            <Route path="/relatorio/movimentosporperiodo" element={<RequireAuthEstoques><MovimentosPorPeriodo /></RequireAuthEstoques>} />
            <Route path="/movimentoestoquecampos" element={<RequireAuthAdm><MovimentoEstoqueCampos /></RequireAuthAdm>} />
            <Route path="/campos" element={<RequireAuthAdm><Campos /></RequireAuthAdm>} />
            <Route path="/valorescomplementares" element={<RequireAuthAdm><ValoresComplementares /></RequireAuthAdm>} />
            <Route path="/requisicoes-kanban" element={<RequireAuthRequisicoes><RequisicoesKanban /></RequireAuthRequisicoes>} />
            <Route path="/colaboradorstatus" element={<RequireAuthDP><ColaboradorStatus /></RequireAuthDP>} />
            <Route path="/tipomovimento-status" element={<RequireAuthAdm><TipoMovimentoStatus /></RequireAuthAdm>} />
            <Route path="/produtomedida" element={<RequireAuthAdm><ProdutoMedida /></RequireAuthAdm>} />
            <Route path="/colaboradores" element={<RequireAuthDP><Colaborador /></RequireAuthDP>} />
            <Route path="/colaboradores-kanban" element={<RequireAuthDP><ColaboradorKanban /></RequireAuthDP>} />
            <Route path="/tipomovimento-produto" element={<RequireAuthAdm><TipoMovimentoProduto /></RequireAuthAdm>} />
            <Route path="/estoques-movimento" element={<RequireAuthEstoques><EstoquesMovimentos /></RequireAuthEstoques>} />
        </Routes>
    )
}