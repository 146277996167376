import { Divider, Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatDecimal, formatPercentColumn, isMobileDevice, toastError } from "../../../commons/utils";
import { Columns, GridCard } from "..";
import { DataTable } from "../../../components/DataTable";
import { ExCard } from "../../../charts/Card";
import BarChart from "../../../charts/Bar";
import PieChart from "../../../charts/pie";
import { ExSelect } from "../../../components/ExSelect";
import { model_produto } from "../../../models";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { Boxes, ShoppingCart } from "lucide-react";
import { ExPaper } from "../../../components/Paper";

export const DashVendas = forwardRef((props: any, ref) => {
    const auth = useAuth()

    const [movVenda, setMovVenda] = useState<any>([]);
    const [originData, setOriginData] = useState<any>([]);
    const [originDataEntrada, setOriginDataEntrada] = useState<any>([]);
    const [originSaldo, setOriginSaldo] = useState<any>();

    const refembalagemvendida = useRef<any>();
    const refpesagemvendida = useRef<any>();
    const refembalagemestoque = useRef<any>();
    const refpesagemestoque = useRef<any>();
    const refvendastamanho = useRef<any>();
    const refvendascategoria = useRef<any>();
    const refproduto = useRef<any>();

    function setSaldo(_data: any) {
        setOriginSaldo(_data);
        sumSaldos(_data);
    }

    useImperativeHandle(ref, () => ({
        setData,
        setSaldo
    }));

    async function changeCultura() {
        try {
            const response: any = await auth.get(model_produto.api_get + '?c.cultid=' + props?.cultura?.id);
            const formattedData = response.data.map((item: any) => ({ value: item.prodid, label: item.proddescricao }));

            await refproduto.current.setCustomOptions(formattedData);
        } catch (error) {
            toastError(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props?.cultura?.id) {
                refproduto.current.setCustomValue(null);
                await changeCultura();
            }

            populateChart(originData, props?.cultura?.descricao, refproduto?.current?.getValue());
            populateGrid(originDataEntrada, originData, props?.cultura?.descricao);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cultura]);

    async function sumTotais(_data: any) {
        const totalembalagem = _data.reduce((acc: number, item: any) => acc + (item.totalembalagem || 0), 0);
        const totalpesagem = _data.reduce((acc: number, item: any) => acc + (item.totalpesagem || 0), 0);

        refembalagemvendida.current.setValue(formatDecimal(totalembalagem));
        refpesagemvendida.current.setValue(formatDecimal(totalpesagem));
    }

    function filterDataByProduct(_data: any, produto: any) {
        if (produto && produto.length > 0) {
            const _produtos = new Set(produto.map((item: any) => item.value));
            return _data.filter((item: any) => _produtos.has(item.prodid || item.prodidsaida));
        }
        return _data;
    }

    async function sumSaldos(_data: any, produto?: any) {
        _data = filterDataByProduct(_data, produto);

        const totalembalagemestoque = _data.reduce((acc: number, item: any) => acc + (item.saldoembalagem || 0), 0);
        const totalpesagemestoque = _data.reduce((acc: number, item: any) => acc + (item.saldopesagem || 0), 0);

        refembalagemestoque.current.setValue(formatDecimal(totalembalagemestoque));
        refpesagemestoque.current.setValue(formatDecimal(totalpesagemestoque));
    }

    async function populateChart(_data: any, cultura?: any, produto?: any) {
        if (cultura)
            _data = _data.filter((item: any) => item.cultdescricao === cultura);

        _data = filterDataByProduct(_data, produto);

        populateChartTamanho(_data);
        populateChartCategoria(_data);
        sumTotais(_data);
    }

    function groupData(data: any, keyField: string, valueField: string, field: string) {
        return data.reduce((acc: any, item: any) => {
            const key = item[keyField];

            if (!acc[key]) {
                acc[key] = { pldescricao: key, [valueField]: 0 };
            }

            acc[key][valueField] += item[field];
            return acc;
        }, {});
    }

    function groupByKey(data: any, keyField: string, valueField: string, sequenceField: string) {
        return data.reduce((acc: any, item: any) => {
            const key = item[keyField];

            if (!acc[key]) {
                acc[key] = { [keyField]: key, [valueField]: 0, [sequenceField]: item[sequenceField] };
            }

            acc[key][valueField] += item[valueField];
            return acc;
        }, {});
    }

    function sortBySequence(data: any[], sequenceField: string) {
        return data.sort((a: any, b: any) => {
            if (a[sequenceField] < b[sequenceField]) return -1;
            if (a[sequenceField] > b[sequenceField]) return 1;
            return 0;
        });
    }

    function populateChartTamanho(_data: any) {
        const groupTamanho = groupByKey(_data, 'culttamdescricao', 'totalembalagem', 'culttamsequencia');
        const _classificacoes = sortBySequence(Object.values(groupTamanho), 'culttamsequencia');

        const tamanhos = _classificacoes.map((item: any) => parseFloat(item.totalembalagem.toFixed(2)));
        const descricoes = _classificacoes.map((item: any) => item.culttamdescricao !== null ? item.culttamdescricao : 'Sem Tamanho');

        const getNextMultipleOf2000 = (value: number) => Math.ceil(value / 2000) * 2000;

        refvendastamanho?.current.setOptions(descricoes, getNextMultipleOf2000(Math.max(...tamanhos)), 'Embalagens Vendidas por Tamanho');
        refvendastamanho?.current.setSeries([{ name: 'Embalagens Vendidas por Tamanho', data: tamanhos }]);
    }

    function populateChartCategoria(_data: any) {
        const groupCategoria = groupByKey(_data, 'cultcatdescricao', 'totalembalagem', 'cultcatsequencia');
        const _classificacoes = sortBySequence(Object.values(groupCategoria), 'cultcatsequencia');

        const categorias = _classificacoes.map((item: any) => parseFloat(item.totalembalagem.toFixed(2)));
        const descricoes = _classificacoes.map((item: any) => item.cultcatdescricao !== null ? item.cultcatdescricao : 'Sem Embalagem');

        refvendascategoria?.current.setLabels(descricoes);
        refvendascategoria?.current.setSeries(categorias);
    }

    function populateGrid(_dataEntrada: any, _dataVenda: any, cultura?: any) {
        if (cultura) {
            _dataEntrada = _dataEntrada.filter((item: any) => item.cultdescricao === cultura);
            _dataVenda = _dataVenda.filter((item: any) => item.cultdescricao === cultura);
        }

        const groupEntrada = groupData(_dataEntrada, 'pldescricao', 'pesagemareaentrada', 'pesagemarea');
        const groupVenda = groupData(_dataVenda, 'pldescricao', 'pesagemareasaida', 'pesagemarea');

        const combinedData = Object.values(groupEntrada).map((entrada: any) => {
            const venda = groupVenda[entrada.pldescricao] || { pesagemsaida: 0 };

            const pesagemareaentrada = entrada.pesagemareaentrada;
            const pesagemareasaida = venda.pesagemareasaida;
            const percentperda = pesagemareaentrada === 0 ? 0 : ((pesagemareaentrada - pesagemareasaida) / pesagemareaentrada) * 100;

            return {
                pldescricao: entrada.pldescricao,
                pesagemareaentrada: pesagemareaentrada,
                pesagemareasaida: pesagemareasaida,
                percentperda
            };
        });

        const sortedData = sortBySequence(combinedData, 'pldescricao');

        setMovVenda(sortedData);
    }

    function setData(_dataEntrada: any, _dataVenda: any) {
        setOriginData(_dataVenda);
        setOriginDataEntrada(_dataEntrada);

        if (_dataVenda && _dataVenda.length > 0)
            props.onChangeCultura({ id: _dataVenda[0].cultid, descricao: _dataVenda[0].cultdescricao });
        else {
            populateChart(_dataVenda);
            populateGrid(_dataEntrada, _dataVenda);
        }
    }

    const columns = [
        { field: 'pldescricao', header: 'Plantio' },
        { field: 'pesagemareaentrada', header: 'Pesagem por ha (Entrada)', body: (e: any) => formatDecimal(e.pesagemareaentrada), dataType: 'numeric' },
        { field: 'pesagemareasaida', header: 'Pesagem por ha (Venda)', body: (e: any) => formatDecimal(e.pesagemareasaida), dataType: 'numeric' },
        { field: 'percentperda', header: '% Perda', body: (e: any) => formatPercentColumn(e.percentperda), dataType: 'numeric' },
    ];

    async function changeProduto(selected: any) {
        populateChart(originData, props?.cultura?.descricao, selected);
        sumSaldos(originSaldo, selected);
    }

    return (
        <div style={{ padding: isMobileDevice() ? '1rem' : '0 4rem' }}>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={isMobileDevice() ? 12 : 6}>
                        <ExSelect ref={refproduto} id='produto' label='Produto' model={model_produto} onAfterChange={changeProduto} isMulti />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ backgroundColor: '#fff' }} />
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ padding: '15px 0px' }}>
                <Grid item xs={isMobileDevice() ? 12 : 6} >
                    <BarChart ref={refvendastamanho} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 6}>
                    <PieChart ref={refvendascategoria} title='Embalagens Vendidas por Categoria' />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <GridCard md={3}>
                            <ExCard ref={refembalagemvendida} title='Embalagens Vendidas' icon={<ShoppingCart />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refpesagemvendida} title='Pesagem Vendida' icon={<ShoppingCart />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refembalagemestoque} title='Embalagens em Estoque' icon={<Boxes />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refpesagemestoque} title='Pesagem em Estoque' icon={<Boxes />} />
                        </GridCard>
                    </Grid>
                </Grid>
                {movVenda.length > 0 &&
                    <Grid item xs={12}>
                        <ExPaper>
                            <DataTable value={movVenda} originvalues={movVenda} disabledrefresh={true}>
                                {Columns(columns)}
                            </DataTable>
                        </ExPaper>
                    </Grid>}
            </Grid >
        </div>
    )
})