import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { formatDataCompare, formatDecimal, formatMonetary, isMobileDevice, toastError } from "../../../commons/utils";
import BarChart from "../../../charts/Bar";
import { Input } from "../../../components/Input";
import PieChart from "../../../charts/pie";
import moment from "moment";
import { GridCard } from "..";
import { ExCard } from "../../../charts/Card";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import AreaChart from "../../../charts/area";
import { Boxes, CheckCheck, CircleHelp, DollarSign, MapPinned, Weight } from "lucide-react";

export const DashPlantio = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const originData = useRef<any[]>([]);

    const refchartpdestino = useRef<any>();
    const refchartexpdedicaodia = useRef<any>();
    const refcharttamanho = useRef<any>();
    const refchartorigem = useRef<any>();
    // const refchartgrupos = useRef<any>()
    // const refchartmensal = useRef<any>();
    const refcardareaestimada = useRef<any>();
    const refcardareaplanejada = useRef<any>();
    const refcardtotal = useRef<any>();
    const refcardtotalemb = useRef<any>();
    const refFilter = useRef<any>({})

    useImperativeHandle(ref, () => ({
        setData
    }));

    const getNextMultipleOf1000 = (value: number) => Math.ceil(value / 1000) * 1000;

    const getValue = (groupByKey: string | ((item: any) => string), item: any) => typeof groupByKey === 'function' ? groupByKey(item) : item[groupByKey] || 'Não Informado'

    const groupData = (data: any[], groupByKey: string | ((item: any) => string)) =>
        data.reduce((acc: Record<string, number>, item: any) => {
            const key = getValue(groupByKey, item);
            const value = parseFloat((item.total || 0).toFixed(2));
            acc[key] = (acc[key] || 0) + value;
            return acc;
        }, {});

    const populateChart = (refChart: any, data: any, options: { groupByKey: any; chartTitle: string; limit?: number; type?: 'Bar' | 'Pie'; labelFontSize?: string, keepOrder?: boolean }) => {
        const { groupByKey, chartTitle, limit = Infinity, type = 'Bar', labelFontSize = '16px' } = options;

        var fdataPointSelection

        if (groupByKey) {
            fdataPointSelection = (e: any, ct: any, { w }: any) => {
                const acceptValues = w.globals.selectedDataPoints[0]?.map((i: number) => w.globals.labels[i]).filter((e: any) => e)

                if (!acceptValues || acceptValues.length === 0)
                    delete refFilter.current[groupByKey]
                else
                    refFilter.current[groupByKey] = (e: any) => acceptValues.includes(getValue(groupByKey, e))

                prePopulate()
            }
        }

        const filterArr = Object.entries(refFilter.current)
        data = [...data].filter((e: any) => filterArr.every(([key, val]: any) => key === groupByKey || val(e)))

        const groupedData = groupData(data, groupByKey);

        let entries: [string, number][];

        if (!options.keepOrder) {
            if (typeof groupByKey === 'function')
                entries = Object.entries(groupedData).sort(([keyA], [keyB]) => {
                    const dateA = moment(keyA, 'MM/YYYY');
                    const dateB = moment(keyB, 'MM/YYYY');
                    return dateA.diff(dateB);
                }).slice(0, limit);
            else
                entries = Object.entries(groupedData).sort(([, a], [, b]) => b - a).slice(0, limit);
        } else
            entries = Object.entries(groupedData)

        const labels = entries.map(([key]) => key);
        const values = entries.map(([, value]) => value);

        if (type === 'Bar') {
            refChart?.current.setOptions(labels, getNextMultipleOf1000(Math.max(...values)), chartTitle, undefined, undefined, undefined, labelFontSize, fdataPointSelection);
            refChart?.current.setSeries([{ name: chartTitle, data: values }]);
        } else if (type === 'Pie') {
            refChart?.current.setLabels(labels);
            refChart?.current.setOptions(fdataPointSelection);
            refChart?.current.setSeries(values);
        }
    };

    const setCardValues = (ref: any, value: any) => {
        ref?.current.setValue(value);
    };

    const prePopulate = () => {
        var filteredData = originData.current;

        const filterArr = Object.entries(refFilter.current)
        const cardData = filteredData.filter((e: any) => filterArr.every(([key, val]: any) => val(e)))

        const areaEstimada = cardData.reduce((sum: any, item: any) => {
            const sementes = (-item.movestqtde) * 1000 / item.medida
            return sum + ((sementes / (item.popsementes / item.popmetroslineares)) / item.popmetroslineares)
        }, 0);

        const pls = new Set(cardData.map(e => e.pldestino))
        const plsArea = Array.from(pls).map(e => cardData.find(el => el.pldestino == e).plarea)

        setCardValues(refcardareaestimada, formatDecimal(areaEstimada));
        setCardValues(refcardareaplanejada, formatDecimal(plsArea.reduce((sum: any, val: any) => sum + val, 0)));
        setCardValues(refcardtotal, formatDecimal(cardData.reduce((sum: any, item: any) => sum - item.movestqtde, 0)));
        setCardValues(refcardtotalemb, formatDecimal(cardData.reduce((sum: any, item: any) => sum - item.movestqtdeunid, 0)));

        // populateChart(refchartmensal, filteredData, {
        //     groupByKey: (item: any) => moment(item.reqdata).format('MM/YYYY'),
        //     chartTitle: 'Valor por Mês',
        // });

        populateChart(refchartpdestino, filteredData.map(e => ({ ...e, total: -e.movestqtde })), {
            groupByKey: 'pldestino',
            chartTitle: 'Quantidade expedida por PL',
            keepOrder: true
        });

        populateChart(refchartorigem, filteredData.map(e => ({ ...e, total: -e.movestqtde })), {
            groupByKey: 'pldescricao',
            chartTitle: 'Origem da Semente',
            type: 'Pie',
        });

        // populateChart(refchartgrupos, filteredData, {
        //     groupByKey: 'grudescricao',
        //     chartTitle: 'Grupos',
        //     type: 'Pie',
        // });

        populateChart(refchartexpdedicaodia, filteredData.map(e => ({ ...e, total: -e.movestqtde })), {
            groupByKey: (item: any) => moment(item.movemissao).format('DD/MM/YYYY'),
            chartTitle: 'Expedição por dia',
        });

        populateChart(refcharttamanho, filteredData.map(e => ({ ...e, total: -e.movestqtde })).sort((a, b) => a.culttamsequencia - b.culttamsequencia), {
            groupByKey: 'culttamdescricao',
            chartTitle: 'Alho plantado por tamanho',
            keepOrder: true
        });
    };

    const setData = (_data: any) => {
        originData.current = _data;
        console.log('setdata', _data)
        prePopulate();
    };

    return (
        <div style={{ padding: isMobileDevice() ? '1rem' : '1rem 4rem' }}>
            <Grid container spacing={4}>
                {/*<Grid item xs={isMobileDevice() ? 12 : 8}>
                    <BarChart ref={refchartexpdedicaodia} />
                </Grid>
                 <Grid item xs={isMobileDevice() ? 12 : 4}>
                    <div style={{ height: '450px' }}>
                        <PieChart ref={refchartgrupos} title='Grupos' monetary />
                    </div>
                </Grid>*/}
                <Grid item xs={isMobileDevice() ? 12 : 12}>
                    <BarChart ref={refchartpdestino} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 8}>
                    <BarChart ref={refcharttamanho} />
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 4}>
                    <div style={{ height: '450px' }}>
                        <PieChart ref={refchartorigem} title='Origem da Semente' />
                    </div>
                </Grid>
                *<Grid item xs={isMobileDevice() ? 12 : 12}>
                    <Grid container spacing={2}>
                        <GridCard md={3}>
                            <ExCard ref={refcardtotal} title='Peso Total' icon={<Weight />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refcardtotalemb} title='Caixas' icon={<Boxes />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refcardareaestimada} title='Área Estimada' icon={<MapPinned />} />
                        </GridCard>
                        <GridCard md={3}>
                            <ExCard ref={refcardareaplanejada} title='Área Planejada' icon={<MapPinned />} />
                        </GridCard>
                    </Grid>
                </Grid>
                <Grid item xs={isMobileDevice() ? 12 : 12}>
                    <AreaChart ref={refchartexpdedicaodia} />
                </Grid>
            </Grid>
        </div>
    );
});
