import { ExButton } from "../../../components/ExButton";
import { copyText, toastError } from '../../../commons/utils';
import { SlotProps } from '../../Gallery';
import { Copy } from "lucide-react";
import { FileProps } from "../../../s3/aws";
import { IContext } from "../../../contexts/AuthProvider/types";

export function getNewStatus(status: any, idAtual: any, next: boolean) {
    const statusAtual = status.find((item: any) => item.id === idAtual);

    const candidatos = status.filter((item: any) => item.rejeitado === 0 && (next ? item.sequencia > statusAtual.sequencia : item.sequencia < statusAtual.sequencia));

    const statusAdjacente = candidatos.reduce((adjacente: any, atual: any) =>
        (!adjacente || (next ? atual.sequencia < adjacente.sequencia : atual.sequencia > adjacente.sequencia)) ? atual : adjacente, null
    );

    return statusAdjacente ? statusAdjacente.id : null;
}

export async function updateStatus(auth: any, status: any, data: any, next: boolean) {
    data.colstatusid = getNewStatus(status, data.colstatusid, next);

    await auth.post('/v1/colaboradorstatuscrm', { colstatusid: data.colstatusid }, { colid: data.colid })
        .then(() => {

        })
        .catch((error: any) => {
            console.log(error)
        })
}

export async function confirmDesistencia(auth: any, coldesid: string, colid: string) {
    await auth.post('/v1/colaboradordesistenciaconfirm', { coldesid, colid, coldessituacao: 1, usucodigoaprovacao: auth.usucodigo, coldesdatahoraconfirmacao: new Date() })
        .then(() => {

        })
        .catch((error: any) => {
            toastError(error)
        })
}

export const ButtonCopy = (props: { text: any }) => {
    return (
        (props.text !== null && props.text !== undefined && props.text.toString().trim() !== '') ?
            <ExButton
                size="sm"
                iconcenter={<Copy width='16px' />}
                color={'transparent'}
                style={{ height: '30px', width: '30px', marginLeft: '6px', display: 'inline-block', padding: 0, color: 'var(--icon-color)' }}
                onClick={() => copyText(props.text)}
            /> :
            <ExButton
                size="sm"
                color={'transparent'}
                style={{ height: '30px', width: '30px', marginLeft: '6px', display: 'inline-block' }}
                disabled
            />
    )
}

export async function getFiles(auth: any, colid: string): Promise<SlotProps[]> {
    try {
        const response: any = await auth.get('/v1/colaboradorarquivocolaborador?colid=' + colid + '&colarqativo=1');

        if (response?.data) {
            const slots: SlotProps[] = response.data.map((item: any) => ({
                url: item.colarqurl,
                type: item.colarqurl.toLowerCase().endsWith(".pdf") ? 'document' : 'image',
                name: item.colarqtipo
            }));

            return slots;
        } else
            return [];
    } catch (error: any) {
        toastError(`Erro ao carregar arquivos: ${error.message || error}`);
        return [];
    }
}
export async function uploadFileColaborador(auth: IContext, colid: string, file: FileProps) {
    const body = {
        colarqid: file.uuid,
        colid,
        colarqtipo: file.filename || 'Geral',
        colarqurl: file.url
    };

    try {
        await auth.post('/v1/colaboradorarquivo', body);
    } catch (error: any) {
        toastError(error);
    }
}