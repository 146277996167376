import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../Modal";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { onValidate } from "../../commons/utils";
import { ExControls } from "../ExControls";
import { Plus } from "lucide-react";

const ExCol = styled(Col)`
    padding-right: .5rem;
    padding-left: .5rem;
    margin-bottom: .5rem;
`

export const ExCadastroPadrao = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const inputsEls = useRef<{ [key: string]: any }>({});

    const refModal = useRef<any>();

    const [validated, setValidated] = useState(false);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [activeTab, setActiveTab] = useState<string>();

    const inputs = props.model.inputs;

    const hasTabs = inputs.some((input: any) => input.tab);
    let autoFocus = false;

    function renderInput(input: any, i: number) {
        const shouldAutoFocus = !autoFocus && !input.disabled;

        if (shouldAutoFocus)
            autoFocus = true;

        return (
            <Form.Group as={ExCol} md={input.md} key={i}>
                <ExControls
                    ref={(e: any) => inputsEls.current[input.id] = e}
                    id={input.id}
                    name={input.id}
                    type={input.type}
                    label={input.label}
                    disabled={data ? (input.disableEdit || input.disabled) : input.disabled}
                    required={input.required}
                    defaultValue={data ? getDataValue(input.id) : input?.defaultValue || ''}
                    options={input.options}
                    model={input.model}
                    autoFocus={shouldAutoFocus}
                    validated={validated}
                    maxLength={input.maxLength}
                    textarea={input.textarea}
                    rows={input.rows}
                    onAfterChange={(_newValue: any) => { input.onAfterChange && input.onAfterChange(_newValue, inputsEls) }}
                />
            </Form.Group>
        );
    }

    const tabs: { [key: string]: JSX.Element[] } = {};

    if (hasTabs) {
        inputs.forEach((input: any, i: number) => {
            const tabKey = input.tab || "default";
            if (!tabs[tabKey]) {
                tabs[tabKey] = [];
            }

            tabs[tabKey].push(renderInput(input, i));
        });
    }

    function openModal(_data: any) {
        setActiveTab('0');
        setData(_data);
        setValidated(false);
        setLoading(false);
        refModal.current.setLoading(false);
        refModal.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    function getDataValue(id: any) {
        const idParts = id.split('.');

        if (idParts?.length === 2) {
            const [firstKey, secondKey] = idParts;
            return data[firstKey][secondKey];
        } else
            return data[id];
    }

    function getValues() {
        const result: any = data ? { ...data } : { ...props.model.base };

        if (inputsEls) {
            Object.values(inputsEls.current).forEach((input: any) => {
                const inputId = input.getId();

                if ((inputId !== props.model.key) || (props.model.consider_key)) {
                    const idParts = inputId.split('.');

                    if (idParts?.length === 2) {
                        const [firstKey, secondKey] = idParts;
                        result[firstKey][secondKey] = input.getValue();
                    } else
                        result[inputId] = input.getValue()
                }
            });
        }

        return result;
    }

    function upsert() {
        async function execute() {
            const values = getValues();

            if (props.model.api_post) {
                setLoading(true);
                refModal.current.setLoading(true);

                await auth.post(props.model.api_post, values).then(() => {
                    toast.success('Registro ' + (values[props.model.key] > 0, 'atualizado', 'cadastrado') + ' com sucesso.');

                    refModal.current.closeModal();

                    if (props.success)
                        props.success();
                }).catch((error: any) => {
                    toast.error(error?.response?.data.error ? error?.response?.data.error : 'Erro ao concluir a operação.');
                    setLoading(false);
                    refModal.current.setLoading(false);
                })
            } else {
                refModal.current.closeModal();

                if (props.success)
                    props.success(values);
            }
        }

        execute();
    }


    function confirmarClick() {
        setValidated(true);

        if (onValidate(inputsEls))
            upsert();
    }

    return (
        <Modal
            ref={refModal}
            icon={<Plus />}
            title={props.model.name}
            size={props.model.size}
            success={() => confirmarClick()}
        >
            <Form noValidate validated={validated}>
                <fieldset disabled={loading}>
                    {hasTabs ? (
                        <Tabs
                            id="cadastro-padrao-tabs"
                            activeKey={activeTab}
                            onSelect={(k: any) => setActiveTab(k)}
                            className="mb-3"
                        >
                            {Object.keys(tabs).map((tabKey, idx) => (
                                <Tab eventKey={idx.toString()} title={tabKey} key={idx}>
                                    <Row>{tabs[tabKey]}</Row>
                                </Tab>
                            ))}
                        </Tabs>
                    ) : (
                        <Row>
                            {inputs.map((input: any, i: number) => renderInput(input, i))}
                        </Row>
                    )}
                </fieldset>
            </Form>
        </Modal>
    );
});
