import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "../Modal";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { setLocalStorage } from "../../commons/utils";
import { ExCheckBox } from "../ExCheckBox";
import { Settings } from "lucide-react";

interface ColumnConfiguratorProps {
    module: string;
    columns: any[];
    setColumns: (columns: any[]) => void;
}

const DraggableContainer = styled.div`
    padding: 6px;
    margin: 10px 4px;
    border: 1px solid var(--line-border-color);
    border-radius: 4px;
    background-color: var(--bg-color-secondary);
    display: flex;
    align-items: center;
`;

export const ColumnConfigurator = forwardRef(({ module, columns, setColumns }: ColumnConfiguratorProps, ref) => {
    const auth = useAuth();

    const refModal = useRef<any>();

    const [orderedColumns, setOrderedColumns] = useState(columns);

    function saveLocalStorage(value: any) {
        setLocalStorage('exconfigtable_' + auth.usucodigo + '_' + module, value);
    }

    const handleToggleColumnVisibility = (column: any) => {
        const updatedColumns = orderedColumns.map((col) => {
            const matchingColumn = columns.find((c) => c.field === col.field);
            return col.field === column.field ? { ...col, invisibleconf: !col.invisibleconf, invisible: matchingColumn?.invisible } : col
        });

        setOrderedColumns(updatedColumns);
        setColumns(updatedColumns);

        const columnsToSave = updatedColumns.map(({ invisible, ...rest }) => rest);

        saveLocalStorage(columnsToSave ?? null);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedColumns = Array.from(orderedColumns);
        const [movedColumn] = updatedColumns.splice(result.source.index, 1);
        updatedColumns.splice(result.destination.index, 0, movedColumn);

        setOrderedColumns(updatedColumns);
        setColumns(updatedColumns);

        saveLocalStorage(updatedColumns ?? null);
    };

    const openModal = () => {
        refModal.current.openModal();
    };

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    return (
        <Modal ref={refModal} title="Configurar Grade" icon={<Settings />} size="sm" >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {orderedColumns.map((column, index) => (
                                <Draggable key={column.field} draggableId={column.field} index={index}>
                                    {(provided) => (
                                        <DraggableContainer
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{ ...provided.draggableProps.style }}
                                        >
                                            <ExCheckBox
                                                type="checkbox"
                                                label={column.field === 'functions' ? 'Funções' : column.header || "Sem Título"}
                                                defaultValue={!column.invisibleconf ? 1 : 0}
                                                onClick={() => handleToggleColumnVisibility(column)}
                                            />
                                        </DraggableContainer>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>
    );
});
