import { toastError } from "../../commons/utils";
import { IContext } from "../../contexts/AuthProvider/types";
import { FileProps } from "../../s3/aws";
import { SlotProps } from "../Gallery";

export const model_observacao = {
    inputs: [
        { id: 'motivo', label: 'Informe o motivo do retorno da situação (opcional)', type: 'text', md: 12, textarea: true, rows: 3 },
    ]
}

export async function getFilesRequisicao(auth: any, reqid: string): Promise<SlotProps[]> {
    try {
        const response: any = await auth.get('/v1/requisicaoimagens?reqid=' + reqid);

        if (response?.data) {
            const slots: SlotProps[] = response.data.map((item: any) => ({
                url: item.reqimgurl,
                type: item.reqimgurl.toLowerCase().endsWith(".pdf") ? 'document' : 'image',
                name: ''
            }));

            return slots;
        } else
            return [];
    } catch (error: any) {
        toastError(`Erro ao carregar arquivos da requisição: ${error.message || error}`);
        return [];
    }
}
export async function uploadFileRequisicao(auth: IContext, reqid: string, file: FileProps, usucodigo?: number) {
    const body = {
        reqimgid: file.uuid,
        reqid,
        reqimgurl: file.url,
        usucodigo
    };

    try {
        await auth.post('/v1/requisicaoimagens', body);
    } catch (error: any) {
        toastError(error);
    }
}

export function hasPrivilegio(status: any[], privilegios: any[], campos: any[], current_id: number, next: boolean): boolean {
    const current_status = status.find((item) => item.id === current_id);

    if (!current_status) return false;

    const possibles = status.filter((item) =>
        next ? item.sequencia > current_status.sequencia : item.sequencia < current_status.sequencia
    );

    const new_status = possibles.reduce((adjacente, atual) =>
        (!adjacente || (next ? atual.sequencia < adjacente.sequencia : atual.sequencia > adjacente.sequencia)) ? atual : adjacente, null
    );

    if (new_status) {
        if (next) {
            if (new_status.assinatura === 1) return false;

            if (campos.length > 0) {
                const campo = campos.find((campo) => campo.statid === new_status.id);

                if (campo) return false;
            }
        }

        const privilegio = privilegios?.find((p) => p.statid === new_status.id);

        if (privilegio?.usugrustincluir === 1)
            return new_status.id
        else
            return false
    }

    return new_status.id;
}

export async function getUsuPrivilegio(auth: IContext) {
    try {
        const response: any = await auth.get(`/v1/usuariogrupostatuspermissaodesc?gruid=${auth.grupo.gruid}&usucodigo=${auth.usucodigo}`);
        return response.data;
    } catch (error: any) {
        toastError(error);
    }
}

export async function getCampos(auth: IContext) {
    try {
        const response: any = await auth.get(`/v1/requisicaocampos?gruid=${auth.grupo.gruid}&reqcampoativo=1`);
        return response.data;
    } catch (error: any) {
        toastError(error);
    }
}

export async function getStatus(auth: IContext) {
    try {
        const response: any = await auth.get('/v1/status');
        const filteredData = response.data
            .filter((item: any) => item.statativo === 1)
            .sort((a: any, b: any) => a.statsequencia - b.statsequencia);

        // const maxStatsequencia = filteredData.reduce(
        //     (max: number, current: any) => (current.statsequencia > max ? current.statsequencia : max),
        //     0
        // );

        const _status = filteredData
            //.filter((item: any) => item.statsequencia !== maxStatsequencia)
            .map((item: any) => ({
                id: item.statid,
                title: item.statdescricao,
                color: item.statcor ? item.statcor.slice(0, -2) : '',
                value: item.statid,
                sequencia: item.statsequencia,
                assinatura: item.statassinatura,
            }));

        return _status;
    } catch (error: any) {
        toastError(error);
    }
}

export async function updateStatus(auth: any, status: any[], privilegios: any[], campos: any[], current_data: any, next: boolean) {
    const reqsituacao = hasPrivilegio(status, privilegios, campos, current_data.statid, next);

    await auth.post('/v1/requisicaostatuscrm',
        {
            reqid: current_data.reqid,
            usucodigo: current_data.usucodigo,
            reqsituacao,
            reqhistmotivo: current_data.motivo
        },
        {
            reqid: current_data.reqid,
            usucodigo: current_data.usucodigo
        })
        .then(() => { })
        .catch((error: any) => {
            console.log(error)
        })
}
